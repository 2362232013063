import React, { useEffect, useState } from "react";
import profile from "../../assets/img/profile-bg.jpg";
import organizationPic from "../../assets/img/organization.png";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../forms/TextField";
import {
  capitalizeFirstLetter,
  dataURLtoFile,
  getLocalData,
  inputValidator,
  phoneFormat,
  stateOptions,
} from "../../utils/functions";
import {
  getBusinessOptions,
  getOrganizationOptions,
  updateUserProfile,
} from "../../store/actions/authAction";
import { setUIKey } from "../../store/actions/uiAction";
import {
  getAds,
  getCurrentUser,
  getUsers,
  updateAds,
  updateAdsPhoto,
  updateProfilePhoto,
} from "../../store/actions/dashboardAction";
import moment from "moment";
import CropModal from "../../modals/crop-modal";
import AddOrganisations from "../../modals/add-organizations";
import { CHURCH_UNITE_URL } from "../../utils/config";
import DeleteUserModal from "../../modals/delete-user";

const organizationSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  address: yup.string().trim().required("Address is Required"),
  city: yup.string().trim().required("City is Required"),
  state: yup.string().trim().required("State is Required"),
  zip: yup
    .string()
    .trim()
    .required("Zip is Required")
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid Zip"),
  adminName: yup.string().trim().required("Contact Name is Required"),
  category: yup.string().trim().required("Category is Required"),
  // website: yup.string().trim().required("Website is Required"),
  description: yup.string().trim().required("Description is Required"),
  title: yup.string().trim().required("Title is Required"),
  businessName: yup.string().trim().required("Business is Required"),
  webLink: yup.string().trim().required("Web Link is Required"),
  status: yup.string(),
});

const OrganizationProfile = ({
  userInfo,
  setUserInfo,
  setPageType,
  editProfile,
  setEditProfile,
  home,
  getDetails,
  ads,
  history,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isLoadingImgWeb, setIsLoadingImgWeb] = useState(false);
  const [success, setSuccess] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicWeb, setProfilePicWeb] = useState(null);
  const [status, setStatus] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [imgType, setImageType] = useState("");
  const [addOrg, setAddOrg] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [bussId, setBussId] = useState("");

  const { error } = useSelector((state) => state.ui);
  const { businessesOptions, organizationOptions } = useSelector(
    (state) => state.auth,
  );

  const userType = getLocalData("userType");

  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(organizationSchema),
  });

  useEffect(() => {
    dispatch(getBusinessOptions());
    dispatch(getOrganizationOptions());
  }, []);

  useEffect(() => {
    if (userType == 3) {
      setValue("businessName", userInfo?.businessId);
      setBussId(userInfo?.businessId);
    }
    setOrgId(userInfo?.orgId);
  }, [userInfo]);

  useEffect(() => {
    if (userInfo) {
      if (ads) {
        setValue("title", userInfo.title);
        setValue("businessName", userInfo.businessId);
        setBussId(userInfo?.businessId);
        setValue("webLink", userInfo.webLink);
        setValue("status", userInfo.status);
      } else {
        setValue("name", userInfo.name);
        setValue("email", userInfo.email);
        setValue("phone", userInfo.phone);
        setValue("orgId", userInfo.orgId);
        setValue("category", userInfo.category);
        setValue("address", userInfo.address);
        setValue("city", userInfo.city);
        setValue("state", userInfo.state);
        setValue("zip", userInfo.zip);
        setValue("adminName", userInfo.adminName);
        setValue("description", userInfo.description);
        setValue("website", userInfo.website);
        setValue(
          "memberSince",
          moment(userInfo?.memberSince).format("YYYY-MM-DD"),
        );
      }
    }
  }, [userInfo]);

  const saveProfileHandler = async (e) => {
    e.preventDefault();

    const data = getValues();

    if (ads) {
      const result = await trigger(["title", "businessName", "webLink"]);

      if (result) {
        const payload = {
          id: userInfo.id,
          title: data?.title,
          link: data?.webLink,
          businessId: data?.businessName,
          orgId,
          bannerImage: profilePic,
          bannerImageWeb: profilePicWeb,
          status: status !== null ? status : userInfo?.status,
        };

        if (!profilePic) delete payload.bannerImage;
        if (!profilePicWeb) delete payload.bannerImageWeb;

        dispatch(setUIKey("error", ""));
        setIsLoading(true);
        const res = await dispatch(updateAds(payload));
        setIsLoading(false);
        if (res?.status === "success") {
          setSuccess(true);
          setProfilePic(null);
          setPageType("table");
          setUserInfo(null);
          setEditProfile(false);
          const list = await dispatch(getAds());

          const updatedRes = list?.find((ad) => ad.id === userInfo?.id);
          if (updatedRes) setUserInfo(updatedRes);

          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        } else {
          dispatch(setUIKey("error", res?.error));
        }
      }
    } else {
      const result = await trigger([
        "name",
        "email",
        "phone",
        "orgId",
        "address",
        "city",
        "zip",
        "state",
        "adminName",
        "description",
        "memberSince",
      ]);

      if (result) {
        const payload = {
          ...data,
          id: userInfo?.id,
          memberSince: userInfo?.type === 2 ? "" : data?.memberSince,
          orgId: userInfo?.type === 2 ? 0 : data?.orgId,
          interests: "",
          type: userInfo?.type,
          profilePic: profilePic,
          bannerImage: profilePicWeb,
          status: status !== null ? status : userInfo?.status,
        };

        if (!profilePic) delete payload.profilePic;
        if (!profilePicWeb || userInfo?.type === 3) delete payload.bannerImage;

        dispatch(setUIKey("error", ""));
        setIsLoading(true);
        const res = await dispatch(updateUserProfile(payload));
        setIsLoading(false);
        if (res?.status === "success") {
          setSuccess(true);
          setProfilePic(null);
          setPageType("table");
          setUserInfo(null);
          setEditProfile(false);

          if (home) {
            dispatch(getCurrentUser());
          } else {
            dispatch(getUsers(userInfo?.type));
          }

          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        } else {
          dispatch(setUIKey("error", res?.error));
        }
      }
    }
  };

  const uploadImage = async (e, type) => {
    setImageType(type);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = (e) => {
        setAvatar(reader.result);
        setIsOpen(true);
      };
    }
  };

  const uploadImageDirect = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoadingImg(true);
      var data = new FormData();

      if (ads) {
        data.append("bannerImage", e.target.files[0]);

        const res = await dispatch(updateAdsPhoto(data));

        if (res?.bannerImage) {
          setProfilePic(res?.bannerImage);
        }
      } else {
        data.append("profilePhoto", e.target.files[0]);

        const res = await dispatch(updateProfilePhoto(data));

        if (res?.profilePic) {
          setProfilePic(res?.profilePic);
        }
      }
      setIsLoadingImg(false);
    }
  };

  const uploadImageAction = async (url) => {
    setIsOpen(false);

    imgType === "mobile" ? setIsLoadingImg(true) : setIsLoadingImgWeb(true);
    var data = new FormData();

    const file = dataURLtoFile(url, "ads_photo.png");

    if (ads) {
      data.append("bannerImage", file);

      const res = await dispatch(updateAdsPhoto(data));

      if (res?.bannerImage) {
        imgType === "mobile"
          ? setProfilePic(res?.bannerImage)
          : setProfilePicWeb(res?.bannerImage);
      }
    } else {
      data.append("profilePhoto", file);

      const res = await dispatch(updateProfilePhoto(data));

      if (res?.profilePic) {
        imgType === "mobile"
          ? setProfilePic(res?.profilePic)
          : setProfilePicWeb(res?.profilePic);
      }
    }
    imgType === "mobile" ? setIsLoadingImg(false) : setIsLoadingImgWeb(false);
  };

  const getOrgName = (orgId) => {
    return organizationOptions?.find((org) => org?.id === orgId)?.name || "-";
  };

  const closeOrgModal = () => {
    setAddOrg(false);
    window.location.reload();
  };

  return (
    <div className="content-wrapper container-home">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4">
          <div
            className="row justify-content-between"
            style={{
              paddingTop: 20,
              paddingLeft: 40,
              paddingRight: 40,
            }}>
            <div className="col-md-4 text-start">
              {setPageType && (
                <a
                  role="button"
                  onClick={() => {
                    setPageType("table");
                    setUserInfo(null);
                    setEditProfile(false);
                  }}>
                  <i className="far fa-arrow-left me-2"></i> Back To Listing
                </a>
              )}
            </div>

            {!editProfile && (
              <div
                className="col-md-8 text-end"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                }}>
                {getLocalData("userType") == 3 && (
                  <a
                    role="button"
                    onClick={() => setAddOrg(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      fill={
                        window.location.href?.includes(CHURCH_UNITE_URL)
                          ? "#134169"
                          : "#006747"
                      }
                      viewBox="0 0 512 512">
                      <path d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z" />
                    </svg>{" "}
                    {/* Add/Switch Organization */}
                    Organization
                  </a>
                )}
                <a
                  style={{ marginLeft: 20 }}
                  role="button"
                  onClick={() => setEditProfile(true)}>
                  <i className="far fa-pen"></i> {ads ? "Edit" : "Edit Profile"}
                </a>
                {/* <a
                  role="button"
                  className="delete-button"
                  style={{ marginLeft: 20 }}
                  onClick={() => setDeleteUserShow(true)}>
                  <i className="far fa-trash"></i> Delete Account
                </a> */}
              </div>
            )}
          </div>

          <div className="card-body">
            <div className="row justify-content-between g-4 mb-4 mb-md-5">
              <div
                className={`col-lg-${ads ? "3" : "2"} col-md-12`}
                style={{ width: 200 }}>
                <label className="text-muted small mb-1">
                  {ads ? "Mobile Banner" : "Profile Picture"}
                </label>
                <img
                  src={
                    profilePic
                      ? `https://clubunite.com/eleo-uploads/uploads/profilephotos/${profilePic}`
                      : ads && userInfo?.imageUrl
                      ? userInfo?.imageUrl
                      : userInfo?.profilePic
                      ? userInfo?.profilePic
                      : organizationPic
                  }
                  className={`img-fluid br-20 ${
                    ads ? "ads-pic" : ""
                  } organization-pic`}
                />
                {editProfile && (
                  <>
                    {isLoadingImg ? (
                      <label
                        for="upload"
                        className="file-upload mb-0 edit-icon"
                        tabindex="0">
                        Uploading
                      </label>
                    ) : (
                      <label
                        for="upload"
                        className="file-upload mb-0 edit-icon"
                        tabindex="0">
                        Change
                        <input
                          type="file"
                          id="upload"
                          className="account-file-input"
                          accept="image/png, image/jpeg, image/jpg"
                          hidden="true"
                          onChange={(e) =>
                            ads
                              ? uploadImage(e, "mobile")
                              : uploadImageDirect(e)
                          }
                        />
                      </label>
                    )}
                  </>
                )}
                {userInfo && userInfo?.type !== 3 && (
                  <>
                    <br />
                    <label className="text-muted small mt-3 mb-1">
                      {ads ? "Web Banner" : "Banner Image"}
                    </label>
                    <img
                      src={
                        profilePicWeb
                          ? `https://clubunite.com/eleo-uploads/uploads/profilephotos/${profilePicWeb}`
                          : ads && userInfo?.imageUrlWeb
                          ? userInfo?.imageUrlWeb
                          : userInfo?.bannerImage
                          ? userInfo?.bannerImage
                          : organizationPic
                      }
                      className={`img-fluid br-20 ${
                        ads ? "ads-pic" : ""
                      } organization-pic`}
                    />
                    {editProfile && (
                      <>
                        {isLoadingImgWeb ? (
                          <label
                            for="upload"
                            className="file-upload mb-0 edit-icon"
                            tabindex="0">
                            Uploading
                          </label>
                        ) : (
                          <label
                            for="upload-web"
                            className="file-upload mb-0 edit-icon"
                            tabindex="0">
                            Change
                            <input
                              type="file"
                              id="upload-web"
                              className="account-file-input"
                              accept="image/png, image/jpeg, image/jpg"
                              hidden="true"
                              onChange={(e) => uploadImage(e, "web")}
                            />
                          </label>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div
                className={`row g-4 mb-4 col-lg-${ads ? "9" : "10"} col-md-12`}>
                <div className={`col-md-${ads ? "12" : "8"}`}>
                  {ads ? (
                    <div className="row g-3 g-md-4 medium">
                      <div className={`col-md-7`}>
                        <label className="text-muted small mb-2">Title</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Title"
                            register={register}
                            fieldName="title"
                            inputClass="form-control edit-form-control"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.title ? userInfo?.title : "-"}
                          </div>
                        )}
                      </div>

                      {userType == 1 && (
                        <div className="col-md-7">
                          <label className="text-muted small mb-2">
                            Organization
                          </label>
                          {editProfile ? (
                            <div className="style-line">
                              <select
                                className="form-select form-control form-control-sm"
                                onChange={(e) => {
                                  setOrgId(+e.target.value);
                                  setValue("businessName", "");
                                  setBussId("");
                                }}>
                                <option
                                  value=""
                                  disabled
                                  selected>
                                  Organization
                                </option>
                                {organizationOptions?.map((val) => (
                                  <option
                                    selected={orgId === val?.id}
                                    value={val?.id}
                                    key={val?.id}>
                                    {val?.name}
                                  </option>
                                ))}
                              </select>
                              <span className="line"></span>
                            </div>
                          ) : (
                            <div className="text-40">
                              {userInfo?.businessName
                                ? userInfo?.businessName
                                : "-"}
                            </div>
                          )}
                        </div>
                      )}
                      {userType != 3 && (
                        <div className="col-md-7">
                          <label className="text-muted small mb-2">
                            Business
                          </label>
                          {editProfile ? (
                            <div className="style-line">
                              <select
                                className="form-select form-control form-control-sm"
                                onChange={(e) => {
                                  setValue("businessName", +e.target.value);
                                  setBussId(+e.target.value);
                                }}>
                                <option
                                  value=""
                                  disabled
                                  selected={!bussId}>
                                  Business
                                </option>
                                {businessesOptions
                                  ?.filter((val) => val?.orgId === orgId)
                                  ?.map((val) => (
                                    <option
                                      selected={bussId === val?.id}
                                      value={val?.id}
                                      key={val?.id}>
                                      {val?.name}
                                    </option>
                                  ))}
                              </select>
                              <span className="line"></span>
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "2px",
                                }}>
                                {errors?.["businessName"]?.message}
                              </p>
                            </div>
                          ) : (
                            <div className="text-40">
                              {userInfo?.businessName
                                ? userInfo?.businessName
                                : "-"}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="col-md-7">
                        <label className="text-muted small mb-2">WebLink</label>

                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Web Link"
                            register={register}
                            fieldName="webLink"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.webLink ? userInfo?.webLink : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label className="text-muted small mb-2">Status</label>
                        {editProfile ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              height: 40,
                            }}>
                            <ul
                              className="status-div"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}>
                              <li>
                                <input
                                  type="radio"
                                  id="pending"
                                  name="selector"
                                  onClick={() => setStatus("0")}
                                  checked={
                                    status
                                      ? status === "0"
                                      : userInfo?.status == "0"
                                  }
                                />
                                <label
                                  for="pending"
                                  className="pending-label">
                                  Pending
                                </label>
                                <div className="check-pending"></div>
                              </li>

                              <li>
                                <input
                                  type="radio"
                                  id="f-option"
                                  name="selector"
                                  onClick={() => setStatus(1)}
                                  checked={
                                    status
                                      ? status === 1
                                      : userInfo?.status == 1
                                  }
                                />
                                <label for="f-option">Approved</label>
                                <div className="check"></div>
                              </li>

                              <li>
                                <input
                                  type="radio"
                                  id="s-option"
                                  name="selector"
                                  checked={
                                    status
                                      ? status === -1
                                      : userInfo?.status == -1
                                  }
                                  onClick={() => setStatus(-1)}
                                />
                                <label
                                  for="s-option"
                                  className="reject-label">
                                  Rejected
                                </label>
                                <div className="reject"></div>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div className="text-40">
                            {userInfo?.status === 0
                              ? "Pending"
                              : userInfo?.status === 1
                              ? "Approved"
                              : userInfo?.status === -1
                              ? "Rejected"
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="row g-3 g-md-4 medium">
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Name</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Name"
                            register={register}
                            fieldName="name"
                            inputClass="form-control edit-form-control"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.name ? userInfo?.name : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">
                          Category
                        </label>
                        {editProfile ? (
                          <>
                            <select
                              disabled={userType != 1 || userInfo?.type == 2}
                              className="form-select form-control form-control-sm"
                              onChange={(e) =>
                                setValue("category", e.target.value)
                              }>
                              <option
                                value=""
                                disabled
                                selected>
                                Category
                              </option>
                              {userInfo?.type === 3 ? (
                                <>
                                  <option
                                    selected={
                                      userInfo?.category == "Coffee Shop"
                                    }
                                    value="Coffee Shop">
                                    Coffee Shop
                                  </option>
                                  <option
                                    selected={
                                      userInfo?.category == "Restaurant"
                                    }
                                    value="Restaurant">
                                    Restaurant
                                  </option>
                                  <option
                                    selected={userInfo?.category == "Car Wash"}
                                    value="Car Wash">
                                    Car Wash
                                  </option>
                                  <option
                                    selected={
                                      userInfo?.category === "Taxi Service"
                                    }
                                    value="Taxi Service">
                                    Taxi Service
                                  </option>
                                </>
                              ) : (
                                <>
                                  <option
                                    selected={
                                      userInfo?.category === "Golf Club"
                                    }
                                    value="Golf Club">
                                    Golf Club
                                  </option>
                                  <option
                                    selected={userInfo?.category === "Church"}
                                    value="Church">
                                    Church
                                  </option>
                                </>
                              )}
                            </select>
                            <span className="line"></span>
                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "2px",
                              }}>
                              {errors?.["category"]?.message}
                            </p>
                          </>
                        ) : (
                          <div className="text-40">
                            {userInfo?.type === 3
                              ? userInfo?.businessCategory ||
                                userInfo?.category ||
                                "-"
                              : userInfo?.category
                              ? userInfo?.category
                              : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Email</label>
                        {editProfile ? (
                          <TextField
                            disabled={true}
                            errors={errors}
                            placeholder="Email"
                            register={register}
                            fieldName="email"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.email ? userInfo?.email : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Phone</label>

                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Phone"
                            register={register}
                            fieldName="phone"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.phone
                              ? phoneFormat(userInfo?.phone)
                              : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Address</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Address"
                            register={register}
                            fieldName="address"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.address ? userInfo?.address : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">City</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="City"
                            register={register}
                            fieldName="city"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.city ? userInfo?.city : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">State</label>
                        {editProfile ? (
                          <div
                            className={`col-md-${
                              editProfile ? "12" : "6"
                            } style-line`}>
                            <select
                              className="form-select form-control form-control-sm"
                              onChange={(e) =>
                                setValue("state", e.target.value)
                              }>
                              <option
                                value=""
                                disabled
                                selected>
                                State
                              </option>
                              {stateOptions?.map((val) => (
                                <option
                                  value={val.code}
                                  selected={
                                    userInfo?.state === val.name ||
                                    userInfo?.state === val.code
                                  }>
                                  {val.name}
                                </option>
                              ))}
                            </select>
                            <span className="line"></span>
                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "2px",
                              }}>
                              {errors?.["state"]?.message}
                            </p>
                          </div>
                        ) : (
                          <div className="text-40">
                            {userInfo?.state ? userInfo?.state : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Zip</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Zip"
                            register={register}
                            fieldName="zip"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.zip ? userInfo?.zip : ""}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Admin</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Contact Name"
                            register={register}
                            fieldName="adminName"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.adminName ? userInfo?.adminName : "-"}
                          </div>
                        )}
                      </div>
                      {userInfo?.type === 3 && (
                        <div className="col-md-6">
                          <label className="text-muted small mb-2">
                            Member Since
                          </label>
                          {editProfile ? (
                            <TextField
                              errors={errors}
                              placeholder="Member Since"
                              register={register}
                              inputType="date"
                              fieldName="memberSince"
                            />
                          ) : (
                            <div className="text-40">
                              {userInfo?.memberSince
                                ? moment(userInfo?.memberSince).format(
                                    "MM/DD/YYYY",
                                  )
                                : "-"}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Twitter</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Twitter"
                            register={register}
                            fieldName="twitter"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.twitter ? userInfo?.twitter : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">
                          Facebook
                        </label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Facebook"
                            register={register}
                            fieldName="facebook"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.facebook ? userInfo?.facebook : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">
                          Instagram
                        </label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Instagram"
                            register={register}
                            fieldName="instagram"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.instagram ? userInfo?.instagram : "-"}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="text-muted small mb-2">Website</label>
                        {editProfile ? (
                          <TextField
                            errors={errors}
                            placeholder="Website"
                            register={register}
                            fieldName="website"
                          />
                        ) : (
                          <div className="text-40">
                            {userInfo?.website ? userInfo?.website : "-"}
                          </div>
                        )}
                      </div>
                      {(userType == "1" || userType == "2") && (
                        <div className="col-md-12">
                          <label className="text-muted small mb-2">
                            Status
                          </label>
                          {editProfile ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                height: 40,
                              }}>
                              <ul
                                className="status-div"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}>
                                <li>
                                  <input
                                    type="radio"
                                    id="pending"
                                    name="selector"
                                    onClick={() => setStatus("pending")}
                                    checked={
                                      status
                                        ? status === "pending"
                                        : userInfo?.status === "pending"
                                    }
                                  />
                                  <label
                                    for="pending"
                                    className="pending-label">
                                    Pending
                                  </label>
                                  <div className="check-pending"></div>
                                </li>

                                <li>
                                  <input
                                    type="radio"
                                    id="f-option"
                                    name="selector"
                                    onClick={() => setStatus("approved")}
                                    checked={
                                      status
                                        ? status === "approved"
                                        : userInfo?.status === "approved"
                                    }
                                  />
                                  <label for="f-option">Approved</label>
                                  <div className="check"></div>
                                </li>

                                <li>
                                  <input
                                    type="radio"
                                    id="s-option"
                                    name="selector"
                                    checked={
                                      status
                                        ? status === "rejected"
                                        : userInfo?.status === "rejected"
                                    }
                                    onClick={() => setStatus("rejected")}
                                  />
                                  <label
                                    for="s-option"
                                    className="reject-label">
                                    Rejected
                                  </label>
                                  <div className="reject"></div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="text-40">
                              {userInfo?.status
                                ? capitalizeFirstLetter(userInfo?.status)
                                : "-"}
                            </div>
                          )}
                        </div>
                      )}

                      {/* <div className="col-md-6">
                    <label className="text-muted small mb-2">Admin Email</label>
                    <div className="text-40">austin@canvasclub.com</div>
                  </div> */}
                    </div>
                  )}
                </div>

                {!ads && (
                  <div className="col-md-4">
                    <label className="text-muted small mb-2">Description</label>
                    {editProfile ? (
                      <>
                        <textarea
                          style={{
                            border: "solid 1px lightgrey",
                            borderRadius: 10,
                            padding: 10,
                          }}
                          {...register(`description`)}
                          className="form-control h-auto text-area"
                          rows="8"
                          placeholder="Provide some information regarding your business"></textarea>
                        <span className="line"></span>
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "2px",
                          }}>
                          {errors?.["description"]?.message}
                        </p>
                      </>
                    ) : (
                      <div className="text-40">
                        <p>
                          {userInfo?.description ? userInfo?.description : "-"}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                {editProfile && (
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <button
                        onClick={saveProfileHandler}
                        disabled={isLoading || isLoadingImg}
                        className="btn w-100 mt-5 wizard-next-btn">
                        {isLoading ? "Updating" : "Update"}
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <button
                        onClick={() =>
                          window.location.pathname === "/profile"
                            ? setEditProfile(false)
                            : setPageType("table")
                        }
                        disabled={isLoading || isLoadingImg}
                        className="btn w-100 mt-5 wizard-next-btn grey-btn">
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {editProfile && success ? (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "green",
                    }}>
                    Profile Update Successfully!
                  </p>
                ) : (
                  editProfile && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                      }}>
                      {error && !isLoading ? error : ""}
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CropModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        closeModal={() => setIsOpen(false)}
        uploadImgage={uploadImageAction}
        imgType={imgType}
        avatar={avatar}
        org={!ads}
      />
      <DeleteUserModal
        modalIsOpen={deleteUserShow}
        setIsOpen={setDeleteUserShow}
        accountProfile={true}
        closeModal={() => setDeleteUserShow(false)}
      />
      {addOrg && (
        <AddOrganisations
          modalIsOpen={addOrg}
          setIsOpen={setAddOrg}
          closeModal={closeOrgModal}
          organizationOptions={organizationOptions}
          getOrgName={getOrgName}
          history={history}
        />
      )}
    </div>
  );
};

export default OrganizationProfile;
