import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "../forms/TextField";
import { getLocalData, inputValidator } from "../utils/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  connectUserAction,
  getOrganizationOptions,
  registerUser,
  sendMessageAction,
} from "../store/actions/authAction";
import { getUsers } from "../store/actions/dashboardAction";

const userSchema = yup.object({
  message: yup.string().trim().required("Message is Required"),
});

const ConnectUserForm = ({ closeModal, businessId, userId, showName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState("form");

  const { error } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const addHandler = async (data) => {
    if (businessId) {
      const payload = {
        ...data,
        businessId,
      };

      setIsLoading(true);
      const res = await dispatch(connectUserAction(payload, true));
      if (res.status === "success") {
        setModalType("success");
      }
      setIsLoading(false);
    } else {
      const payload = {
        ...data,
        userId,
      };

      setIsLoading(true);
      const res = await dispatch(sendMessageAction(payload, true));
      if (res.status === "success") {
        setModalType("success");
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="authentication-wrapper add-member-modal">
      <div className="form-wizard">
        <form className="position-relative" onSubmit={handleSubmit(addHandler)}>
          <div className={`d-flex authentication-bg p-sm-5 p-4 login-ss`}>
            {modalType === "success" ? (
              <div style={{ width: "300px", paddingTop: "30px" }}>
                <h5 className="mb-4 text-center">
                  {businessId
                    ? "Connection Request sent."
                    : "Message sent."}
                </h5>

                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ paddingTop: 15 }}>
                  <button
                    role="submit"
                    className="btn w-100"
                    onClick={closeModal}
                    disabled={isLoading}>
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <div className="form-block mobile-margin-top">
                <div className="style-line mb-3">
                  <label className="mb-2">{showName}</label>
                  <textarea
                    style={{
                      border: "solid 1px lightgrey",
                      borderRadius: 10,
                      padding: 10,
                      width: 250,
                    }}
                    {...register(`message`)}
                    className="form-control h-auto text-area"
                    rows="8"
                    placeholder="Type Message"></textarea>
                  <span className="line"></span>
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "2px",
                    }}>
                    {errors?.["message"]?.message}
                  </p>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn w-100 mt-3 wizard-next-btn">
                  {isLoading ? "Saving" : "Connect"}
                </button>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    marginTop: 5,
                  }}>
                  {error && !isLoading ? error : ""}
                </p>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConnectUserForm;
