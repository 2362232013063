import React, { useEffect, useRef, useState } from "react";
import defaultPic from "../../assets/img/profile-default.png";
import organizationPic from "../../assets/img/organization.png";
import building from "../../assets/img/icon-building.svg";
import iconUser from "../../assets/img/icon-user.svg";
import iconBriefcase from "../../assets/img/icon-briefcase.svg";
import iconBroadcast from "../../assets/img/icon-broadcast.svg";
import {
  getAds,
  getConnectedBusiness,
  getUsers,
} from "../../store/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getLocalData } from "../../utils/functions";
import Profile from "./member-profile";
import {
  getOrganizationOptions,
  getUserProfileById,
} from "../../store/actions/authAction";
import OrganizationProfile from "./organization-profile";
import ConnectUser from "../../modals/connect-user";
import AdminChangePassword from "../../modals/admin-change-password";
import DeleteUserModal from "../../modals/delete-user";
import BussinessDetailsModal from "../../modals/bussiness-details-modal";
import { useComponentVisible } from "../../apis/hooks";
import {
  CHURCH_UNITE_URL,
  CHURCH_URL_PREFIX,
  CLUB_URL_PREFIX,
} from "../../utils/config";

const Home = ({ history }) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDataBus, setSelectedDataBus] = useState(null);
  const [pageType, setPageType] = useState("table");
  const [userInfo, setUserInfo] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [modalIsOpenUser, setIsOpenUser] = useState(false);
  const [showName, setShowName] = useState(null);
  const [changePasswordMember, setChangePasswordMember] = useState(false);
  const [bussinessDetailsShow, setBussinessDetailsShow] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [deleteUserType, setDeleteUserType] = useState(false);
  const [userId, setUserId] = useState(null);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const { members, businesses, organization, ads, connectedBussiness } =
    useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getUsers());
      dispatch(getUsers(3));
      dispatch(getUsers(4));
      dispatch(getAds());
      dispatch(getOrganizationOptions());

      // if (getLocalData("userType") == 3) {
      //   dispatch(getConnectedBusiness());
      // }
    }
  }, []);

  const userType = getLocalData("userType");

  const getOrgName = (orgId) => {
    return organizationOptions?.find((org) => org?.id === orgId)?.name || "-";
  };

  const getDetails = async (id, type, isEdit) => {
    setUserInfo(null);

    if (isEdit) {
      setEditProfile(true);
    } else {
      setEditProfile(false);
    }
    setSelectedData(null);
    setSelectedDataBus(null);

    if (!isEdit && type !== "ads") {
      setBussinessDetailsShow(true);
    } else {
      setPageType(type);
    }

    if (type === "ads") {
      const res = ads.find((ad) => ad.id === id);
      setUserInfo(res);
    } else {
      const res = await dispatch(getUserProfileById(id));
      setUserInfo(res);
    }
  };

  return (
    <>
      {pageType === "table" ? (
        <div className="container-xxl flex-grow-1 container-p-b container-home">
          <div className="row analytics-block mt-2">
            {userType == 1 && (
              <a
                role="button"
                onClick={() =>
                  history.push(
                    window.location.href?.includes(CHURCH_UNITE_URL)
                      ? `${CHURCH_URL_PREFIX}/organization`
                      : `${CLUB_URL_PREFIX}/organization`,
                  )
                }
                className="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-4">
                <div className="card border-0 bg1">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="card-icon">
                        <img
                          src={building}
                          height="40"
                        />
                      </div>
                      <div className="card-info">
                        <h4 className="card-title semi mb-0">
                          {organization?.length}
                        </h4>
                        <div>Total Organizations</div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )}

            <a
              role="button"
              onClick={() =>
                history.push(
                  window.location.href?.includes(CHURCH_UNITE_URL)
                    ? `${CHURCH_URL_PREFIX}/members`
                    : `${CLUB_URL_PREFIX}/members`,
                )
              }
              className="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-4">
              <div className="card border-0 bg2">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="card-icon">
                      <img
                        src={iconUser}
                        height="45"
                      />
                    </div>
                    <div className="card-info">
                      <h4 className="card-title semi mb-0">
                        {members?.length}
                      </h4>
                      <div>Total Members</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <a
              role="button"
              onClick={() =>
                history.push(
                  window.location.href?.includes(CHURCH_UNITE_URL)
                    ? `${CHURCH_URL_PREFIX}/businesses`
                    : `${CLUB_URL_PREFIX}/businesses`,
                )
              }
              className="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-4">
              <div className="card border-0 bg3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="card-icon">
                      <img
                        src={iconBriefcase}
                        height="40"
                      />
                    </div>
                    <div className="card-info">
                      <h4 className="card-title semi mb-0">
                        {
                          // getLocalData("userType") == 3
                          //   ? connectedBussiness?.length
                          //   :
                          businesses?.length
                        }
                      </h4>
                      <div>Total Businesses</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <a
              role="button"
              onClick={() =>
                history.push(
                  window.location.href?.includes(CHURCH_UNITE_URL)
                    ? `${CHURCH_URL_PREFIX}/ads`
                    : `${CLUB_URL_PREFIX}/ads`,
                )
              }
              className="col-lg-3 col-md-6 col-sm-6 mb-3 mb-md-4">
              <div className="card border-0 bg4">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="card-icon">
                      <img
                        src={iconBroadcast}
                        height="43"
                      />
                    </div>
                    <div className="card-info">
                      <h4 className="card-title semi mb-0">{ads?.length}</h4>
                      <div>Total Ads</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="card mb-3 mb-md-4">
            <h5 className="card-header pb-3 medium">
              {userType == 3 ? "Recently Connected" : "Recent"} Members
            </h5>
            <div className="card-body">
              <div className="table-responsive-xs text-nowrap">
                <table className="table table-borderless medium data-table-block">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Organization</th>
                      <th>Member Since</th>
                      <th>{userType == 3 ? "Connected" : "Last Login"} </th>
                      {userType == 3 ? (
                        ""
                      ) : (
                        <th className="hide-arrow">Actions</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0 text-1e">
                    {members?.slice(0, 3)?.map((user) => (
                      <tr key={user?.id}>
                        <td className="col-md-3">
                          <img
                            src={
                              user?.profilePic ? user?.profilePic : defaultPic
                            }
                            alt="Avatar"
                            className="w-px-30 h-px-30  rounded-circle me-2"
                          />
                          {user?.name}
                        </td>
                        <td className="col-md-2">{getOrgName(user?.orgId)}</td>
                        <td className="col-md-2">
                          {user?.memberSince
                            ? moment(user?.memberSince).format("MM/DD/YYYY")
                            : moment(user?.created_at).format("MM/DD/YYYY")}
                        </td>
                        <td className="col-md-2">
                          {moment(user?.lastLogin).format("MM/DD/YYYY")}
                        </td>
                        {userType == 3 ? (
                          <td className="col-md-2">
                            <a
                              role="button"
                              className="btn table-btn"
                              target="_default"
                              href={
                                "mailto:" +
                                user?.email +
                                "?subject=Member Unite Connect"
                              }
                              // onClick={() => {
                              //   setIsOpenUser(true);
                              //   setUserId(user?.id);
                              //   setShowName(user?.name);
                              // }}
                            >
                              Message
                            </a>
                            <br />
                            <a
                              role="button"
                              style={{
                                marginTop: "10px",
                                paddingLeft: "16px",
                                fontSize: 10,
                                textAlign: "center",
                              }}
                              onClick={() =>
                                getDetails(user?.id, "user-profile")
                              }>
                              View Profile
                            </a>
                          </td>
                        ) : (
                          <td className="col-md-4">
                            <div className="dropdown">
                              <button
                                onClick={() => {
                                  setSelectedData(user.id);
                                  setSelectedDataBus(null);
                                  setIsComponentVisible(true);
                                }}
                                type="button"
                                className={`btn btn-outline dropdown-toggle hide-arrow ${
                                  selectedData === user?.id ? "show" : ""
                                }`}
                                data-bs-toggle="dropdown">
                                {" "}
                                <i className="fas fa-ellipsis-h"></i>
                              </button>
                              <div
                                ref={ref}
                                className={`dropdown-menu ${
                                  selectedData === user?.id &&
                                  isComponentVisible
                                    ? "show"
                                    : ""
                                } `}>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() =>
                                    getDetails(user?.id, "user-profile")
                                  }>
                                  <i className="far fa-eye me-1"></i> View
                                  Profile
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() =>
                                    getDetails(user?.id, "user-profile", true)
                                  }>
                                  <i className="far fa-edit me-1"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    setDeleteUserShow(true);
                                    setSelectedData(null);
                                    setUserId(user);
                                    setDeleteUserType(4);
                                  }}>
                                  <i className="far fa-trash-alt me-1"></i>{" "}
                                  Delete
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    setChangePasswordMember(true);
                                    setSelectedData(null);
                                    setUserId(user?.id);
                                  }}>
                                  <i className="far fa-lock-alt me-1"></i> Reset
                                  Password
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  target="_default"
                                  href={
                                    "mailto:" +
                                    user?.email +
                                    "?subject=Member Unite Connect"
                                  }
                                  // onClick={() => {
                                  //   setIsOpenUser(true);
                                  //   setUserId(user?.id);
                                  //   setShowName(user?.name);
                                  //   setSelectedData(null);
                                  // }}
                                >
                                  <i className="far fa-comment me-1"></i>{" "}
                                  Message
                                </a>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!isLoading && !members?.length && (
                  <div className="loading-text">
                    <p>No Data</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card mb-3 mb-md-4">
            <h5 className="card-header pb-3 medium">Recent Businesses</h5>
            <div className="card-body">
              <div className="table-responsive-xs text-nowrap-xs">
                <table
                  className="table table-borderless medium"
                  id="basic_datatable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>
                        {userType == 2 || userType == 1
                          ? "Address"
                          : "Organization"}
                      </th>
                      <th>
                        {userType == 2 || userType == 1
                          ? "Signup Date"
                          : "Member Since"}{" "}
                      </th>
                      <th>
                        {userType == 2 || userType == 1
                          ? "Business"
                          : "Connected"}{" "}
                      </th>
                      {(userType == 2 || userType == 1) && (
                        <>
                          <th>Total Ads</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  {getLocalData("userType") == 3 ? (
                    <tbody className="table-border-bottom-0 text-1e">
                      {businesses?.slice(0, 3)?.map((business) => (
                        <tr key={business?.id}>
                          <td className="col-md-3">
                            <img
                              src={
                                business?.profilePic
                                  ? business?.profilePic
                                  : organizationPic
                              }
                              alt="Avatar"
                              className="w-px-30 h-px-30 rounded-circle me-2"
                            />
                            {business?.name}
                          </td>
                          {userType == 2 || userType == 1 ? (
                            <td className="col-md-2">
                              {!business?.address ? "-" : ""}{" "}
                              {business?.address} <br /> {business?.city}
                              {business?.address ? "," : ""} {business?.state}{" "}
                            </td>
                          ) : (
                            <td className="col-md-2">
                              {getOrgName(business?.orgId)}
                            </td>
                          )}
                          <td className="col-md-2">
                            {business?.memberSince
                              ? moment(business?.memberSince).format(
                                  "MM/DD/YYYY",
                                )
                              : moment().format("MM/DD/YYYY")}
                          </td>
                          <td className="col-md-2">
                            {userType == 2 || userType == 1
                              ? business?.category
                                ? business?.category
                                : "-"
                              : business?.lastLogin
                              ? moment(business?.lastLogin).format("MM/DD/YYYY")
                              : "Never"}
                          </td>
                          {(userType == 2 || userType == 1) && (
                            <>
                              <td className="col-md-2">0</td>
                              <td className="col-md-2">
                                <span
                                  class={`badge bg-label-${
                                    business?.status === "approved"
                                      ? "success"
                                      : business?.status === "rejected"
                                      ? "danger"
                                      : "warning"
                                  } `}>
                                  {business?.status === "approved"
                                    ? "Approved"
                                    : business?.status === "rejected"
                                    ? "Rejected"
                                    : "Pending"}{" "}
                                </span>
                              </td>
                            </>
                          )}
                          {userType == 3 ? (
                            <td className="col-md-2">
                              <a
                                role="button"
                                // target="_default"
                                // href={
                                //   "mailto:" +
                                //   business?.email +
                                //   "?subject=Member Unite Connect"
                                // }
                                onClick={() => {
                                  setIsOpenUser(true);
                                  setUserId(business?.id);
                                  setShowName(business?.name);
                                }}
                                className="btn table-btn">
                                Connect
                              </a>
                              <br />
                              <a
                                role="button"
                                style={{
                                  marginTop: "10px",
                                  paddingLeft: "16px",
                                  fontSize: 10,
                                  textAlign: "center",
                                }}
                                onClick={() =>
                                  getDetails(
                                    business?.id,
                                    "bussiness-profile",
                                    false,
                                  )
                                }>
                                View Profile
                              </a>
                            </td>
                          ) : (
                            <td className="col-md-2">
                              <div className="dropdown">
                                <button
                                  onClick={() => {
                                    setSelectedDataBus(business.id);
                                    setIsComponentVisible(true);
                                    setSelectedData(null);
                                  }}
                                  type="button"
                                  className={`btn btn-outline dropdown-toggle hide-arrow ${
                                    selectedDataBus === business?.id
                                      ? "show"
                                      : ""
                                  }`}
                                  data-bs-toggle="dropdown">
                                  {" "}
                                  <i className="fas fa-ellipsis-h"></i>
                                </button>
                                <div
                                  ref={ref}
                                  className={`dropdown-menu dropdown-menu-top ${
                                    selectedDataBus === business?.id &&
                                    isComponentVisible
                                      ? "show"
                                      : ""
                                  }`}>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() =>
                                      getDetails(
                                        business?.id,
                                        "bussiness-profile",
                                      )
                                    }>
                                    <i className="far fa-eye me-1"></i> View
                                    Profile
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() =>
                                      getDetails(
                                        business?.id,
                                        "bussiness-profile",
                                        true,
                                      )
                                    }>
                                    <i className="far fa-edit me-1"></i> Edit
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      setDeleteUserShow(true);
                                      setSelectedDataBus(null);
                                      setUserId(business);
                                      setDeleteUserType(3);
                                    }}>
                                    <i className="far fa-trash-alt me-1"></i>{" "}
                                    Delete
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      setChangePasswordMember(true);
                                      setSelectedDataBus(null);
                                      setUserId(business?.id);
                                    }}>
                                    <i className="far fa-lock-alt me-1"></i>{" "}
                                    Reset Password
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    target="_default"
                                    href={
                                      "mailto:" +
                                      business?.email +
                                      "?subject=Member Unite Connect"
                                    }
                                    // onClick={() => {
                                    //   setIsOpenUser(true);
                                    //   setUserId(business?.id);
                                    //   setShowName(business?.name);
                                    //   setSelectedDataBus(null);
                                    // }}
                                  >
                                    <i className="far fa-comment me-1"></i>{" "}
                                    Message
                                  </a>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="table-border-bottom-0 text-1e">
                      {businesses?.slice(0, 3)?.map((business) => (
                        <tr key={business?.id}>
                          <td>
                            <img
                              src={
                                business?.profilePic
                                  ? business?.profilePic
                                  : organizationPic
                              }
                              alt="Avatar"
                              className="w-px-30 h-px-30 rounded-circle me-2"
                            />
                            {business?.name}
                          </td>
                          {userType == 2 || userType == 1 ? (
                            <td>
                              {!business?.address ? "-" : ""}{" "}
                              {business?.address} <br /> {business?.city}
                              {business?.address ? "," : ""} {business?.state}{" "}
                            </td>
                          ) : (
                            <td>{getOrgName(business?.orgId)}</td>
                          )}
                          <td>
                            {business?.memberSince
                              ? moment(business?.memberSince).format(
                                  "MM/DD/YYYY",
                                )
                              : moment().format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {userType == 2 || userType == 1
                              ? business?.category
                                ? business?.category
                                : "-"
                              : moment().format("MM/DD/YYYY")}
                          </td>
                          {(userType == 2 || userType == 1) && (
                            <>
                              <td>
                                {business?.totalAds ? business?.totalAds : 0}
                              </td>
                              <td>
                                <span
                                  class={`badge bg-label-${
                                    business?.status === "approved"
                                      ? "success"
                                      : business?.status === "rejected"
                                      ? "danger"
                                      : "warning"
                                  } `}>
                                  {business?.status === "approved"
                                    ? "Approved"
                                    : business?.status === "rejected"
                                    ? "Rejected"
                                    : "Pending"}{" "}
                                </span>
                              </td>
                            </>
                          )}
                          {userType == 3 ? (
                            <td>
                              <a
                                role="button"
                                target="_default"
                                href={
                                  "mailto:" +
                                  business?.email +
                                  "?subject=Member Unite Connect"
                                }
                                // onClick={() => {
                                //   setIsOpenUser(true);
                                //   setUserId(business?.id);
                                //   setShowName(business?.name);
                                // }}
                                className="btn table-btn">
                                Message
                              </a>
                            </td>
                          ) : (
                            <td>
                              <div className="dropdown">
                                <button
                                  onClick={() => {
                                    setSelectedDataBus(business.id);
                                    setIsComponentVisible(true);
                                    setSelectedData(null);
                                  }}
                                  type="button"
                                  className={`btn btn-outline dropdown-toggle hide-arrow ${
                                    selectedDataBus === business?.id
                                      ? "show"
                                      : ""
                                  }`}
                                  data-bs-toggle="dropdown">
                                  {" "}
                                  <i className="fas fa-ellipsis-h"></i>
                                </button>
                                <div
                                  ref={ref}
                                  className={`dropdown-menu dropdown-menu-top ${
                                    selectedDataBus === business?.id &&
                                    isComponentVisible
                                      ? "show"
                                      : ""
                                  }`}>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() =>
                                      getDetails(
                                        business?.id,
                                        "bussiness-profile",
                                      )
                                    }>
                                    <i className="far fa-eye me-1"></i> View
                                    Profile
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() =>
                                      getDetails(
                                        business?.id,
                                        "bussiness-profile",
                                        true,
                                      )
                                    }>
                                    <i className="far fa-edit me-1"></i> Edit
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      setDeleteUserShow(true);
                                      setSelectedDataBus(null);
                                      setUserId(business);
                                      setDeleteUserType(3);
                                    }}>
                                    <i className="far fa-trash-alt me-1"></i>{" "}
                                    Delete
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      setChangePasswordMember(true);
                                      setSelectedDataBus(null);
                                      setUserId(business?.id);
                                    }}>
                                    <i className="far fa-lock-alt me-1"></i>{" "}
                                    Reset Password
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    target="_default"
                                    href={
                                      "mailto:" +
                                      business?.email +
                                      "?subject=Member Unite Connect"
                                    }
                                    // onClick={() => {
                                    //   setIsOpenUser(true);
                                    //   setUserId(business?.id);
                                    //   setShowName(business?.name);
                                    //   setSelectedDataBus(null);
                                    // }}
                                  >
                                    <i className="far fa-comment me-1"></i>{" "}
                                    Message
                                  </a>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>

                {getLocalData("userType") == 3 ? (
                  <>
                    {!isLoading && !businesses?.length && (
                      <div className="loading-text">
                        <p>No Data</p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!isLoading && !businesses?.length && (
                      <div className="loading-text">
                        <p>No Data</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {userType == 1 && (
            <div className="card mb-3 mb-md-4">
              <h5 className="card-header pb-3 medium">Recent Organizations</h5>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap-xs">
                  <table
                    className="table table-borderless medium"
                    id="basic_datatable">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Category</th>
                        <th>Members</th>
                        <th>Businesses</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 text-1e">
                      {organization?.slice(0, 3)?.map((org) => (
                        <tr key={org?.id}>
                          <td>
                            <img
                              src={
                                org?.profilePic
                                  ? org?.profilePic
                                  : organizationPic
                              }
                              alt="Avatar"
                              className="w-px-30 h-px-30 rounded-circle me-2"
                            />
                            {org?.name}
                          </td>
                          <td>
                            {!org?.address ? "-" : ""} {org?.address} <br />{" "}
                            {org?.city}
                            {org?.address ? "," : ""} {org?.state}{" "}
                          </td>
                          <td>{org?.category ? org?.category : "-"}</td>
                          <td>{org?.totalMembers ? org?.totalMembers : 0}</td>
                          <td>
                            {org?.totalBusinesses ? org?.totalBusinesses : 0}
                          </td>
                          <td>
                            <span
                              class={`badge bg-label-${
                                org?.status === "approved"
                                  ? "success"
                                  : org?.status === "rejected"
                                  ? "danger"
                                  : "warning"
                              } `}>
                              {org?.status === "approved"
                                ? "Approved"
                                : org?.status === "rejected"
                                ? "Rejected"
                                : "Pending"}{" "}
                            </span>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                onClick={() => {
                                  setSelectedDataBus(org.id);
                                  setIsComponentVisible(true);
                                  setSelectedData(null);
                                }}
                                type="button"
                                className={`btn btn-outline dropdown-toggle hide-arrow ${
                                  selectedDataBus === org?.id ? "show" : ""
                                }`}
                                data-bs-toggle="dropdown">
                                <i className="fas fa-ellipsis-h"></i>
                              </button>
                              <div
                                ref={ref}
                                className={`dropdown-menu dropdown-menu-top ${
                                  selectedDataBus === org?.id &&
                                  isComponentVisible
                                    ? "show"
                                    : ""
                                } `}>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => getDetails(org?.id)}>
                                  <i className="far fa-eye me-1"></i> View
                                  Profile
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    getDetails(
                                      org?.id,
                                      "bussiness-profile",
                                      true,
                                    );
                                    window.scroll(0, 0);
                                  }}>
                                  <i className="far fa-edit me-1"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    setDeleteUserShow(true);
                                    setSelectedData(null);
                                    setUserId(org);
                                    setDeleteUserType(2);
                                  }}>
                                  <i className="far fa-trash-alt me-1"></i>{" "}
                                  Delete
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    setChangePasswordMember(true);
                                    setSelectedData(null);
                                    setUserId(org?.id);
                                  }}>
                                  <i className="far fa-lock-alt me-1"></i> Reset
                                  Password
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  target="_default"
                                  href={
                                    "mailto:" +
                                    org?.email +
                                    "?subject=Member Unite Connect"
                                  }
                                  // onClick={() => {
                                  //   setIsOpenUser(true);
                                  //   setUserId(org?.id);
                                  //   setShowName(org?.name);
                                  //   setSelectedData(null);
                                  // }}
                                >
                                  <i className="far fa-comment me-1"></i>{" "}
                                  Message
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!isLoading && !organization?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="card mb-3 mb-md-4">
            <h5 className="card-header pb-3 medium">Recent Ads</h5>
            <div className="card-body">
              <div className="table-responsive-xs text-nowrap-xs">
                <table
                  className="table table-borderless medium"
                  id="basic_datatable">
                  <thead>
                    <tr>
                      <th>Banner</th>
                      <th>Title</th>
                      <th>Business</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0 text-1e">
                    {ads?.map((ad) => (
                      <tr key={ad?.id}>
                        <td>
                          <img
                            src={ad?.imageUrl ? ad?.imageUrl : organizationPic}
                            alt="Avatar"
                            className="w-px-40 h-auto ads-list-pic me-2"
                          />
                          {ad?.name}
                        </td>
                        <td>{ad.title}</td>
                        <td>{ad?.businessName}</td>
                        <td>{moment(ad.createdDate).format("MM/DD/YYYY")}</td>
                        <td>
                          <span
                            class={`badge bg-label-${
                              ad?.status === 1
                                ? "success"
                                : ad?.status === -1
                                ? "danger"
                                : "warning"
                            } `}>
                            {ad?.status === 1
                              ? "Approved"
                              : ad?.status === -1
                              ? "Rejected"
                              : "Pending"}{" "}
                          </span>
                        </td>
                        <td>
                          <div className="dropdown">
                            <button
                              onClick={() => {
                                setSelectedData(ad.id);
                                setIsComponentVisible(true);
                              }}
                              type="button"
                              className={`btn btn-outline dropdown-toggle hide-arrow ${
                                selectedData === ad?.id ? "show" : ""
                              }`}
                              data-bs-toggle="dropdown">
                              <i className="fas fa-ellipsis-h"></i>
                            </button>
                            <div
                              ref={ref}
                              className={`dropdown-menu dropdown-menu-top-ads ${
                                selectedData === ad?.id && isComponentVisible
                                  ? "show"
                                  : ""
                              } `}>
                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={() => getDetails(ad?.id, "ads")}>
                                <i className="far fa-eye me-1"></i> View
                              </a>
                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={() => getDetails(ad?.id, "ads", true)}>
                                <i className="far fa-edit me-1"></i> Edit
                              </a>
                              <a
                                className="dropdown-item"
                                role="button"
                                onClick={() => {
                                  setDeleteUserShow(true);
                                  setSelectedData(null);
                                  setUserId(ad?.id);
                                  setDeleteUserType(0);
                                }}>
                                <i className="far fa-trash-alt me-1"></i> Delete
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!isLoading && !ads?.length && (
                  <div className="loading-text">
                    <p>No Data</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <ConnectUser
            modalIsOpen={modalIsOpenUser}
            setIsOpen={setIsOpenUser}
            userId={userId}
            showName={showName}
            closeModal={() => setIsOpenUser(false)}
          />
          <AdminChangePassword
            modalIsOpen={changePasswordMember}
            setIsOpen={setChangePasswordMember}
            userId={userId}
            closeModal={() => setChangePasswordMember(false)}
          />
          <DeleteUserModal
            modalIsOpen={deleteUserShow}
            setIsOpen={setDeleteUserShow}
            userId={userId}
            userType={deleteUserType}
            ads={deleteUserType === 0}
            closeModal={() => setDeleteUserShow(false)}
          />
          <BussinessDetailsModal
            modalIsOpen={bussinessDetailsShow}
            setIsOpen={setBussinessDetailsShow}
            userInfo={userInfo}
            closeModal={() => setBussinessDetailsShow(false)}
          />
        </div>
      ) : pageType === "user-profile" ? (
        <Profile
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setPageType={setPageType}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          getDetails={getDetails}
          history={history}
        />
      ) : pageType === "ads" ? (
        <OrganizationProfile
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setPageType={setPageType}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          getDetails={getDetails}
          ads={true}
          history={history}
        />
      ) : (
        <OrganizationProfile
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setPageType={setPageType}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          getDetails={getDetails}
          history={history}
        />
      )}
    </>
  );
};

export default Home;
