import React, { useEffect, useState } from "react";
import organizationPic from "../../assets/img/organization.png";
import { getUsers } from "../../store/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { showDataPerPage } from "../../utils/functions";
import { useComponentVisible, usePagination } from "../../apis/hooks";
import Pagination from "./Pagination";
import AddBusiness from "../../modals/add-businesses";
import OrganizationProfile from "./organization-profile";
import { getUserProfileById } from "../../store/actions/authAction";
import ConnectUser from "../../modals/connect-user";
import AdminChangePassword from "../../modals/admin-change-password";
import DeleteUserModal from "../../modals/delete-user";
import BussinessDetailsModal from "../../modals/bussiness-details-modal";

const Organization = ({ history }) => {
  const [selectedData, setSelectedData] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenUser, setIsOpenUser] = useState(false);
  const [userId, setUserId] = useState(null);
  const [pageType, setPageType] = useState("table");
  const [userInfo, setUserInfo] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [showName, setShowName] = useState(null);
  const [changePasswordMember, setChangePasswordMember] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [bussinessDetailsShow, setBussinessDetailsShow] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [showData, setShowData] = useState([]);
  const dispatch = useDispatch();

  const { organization } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const pagination = usePagination(organization?.length, showDataPerPage);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  useEffect(() => {
    if (organization?.length && pagination.current > 0) {
      setShowData(
        organization
          ?.filter((org) =>
            org?.name?.toLowerCase()?.includes(searchText?.toLocaleLowerCase()),
          )
          ?.slice(
            (pagination.current - 1) * showDataPerPage,
            pagination.current * showDataPerPage,
          ),
      );
    }
  }, [organization, pagination.current, searchText]);

  const getDetails = async (id, isEdit) => {
    setUserInfo(null);
    if (isEdit) {
      setEditProfile(true);
    } else {
      setEditProfile(false);
    }
    setSelectedData(null);
    if (!isEdit) {
      setBussinessDetailsShow(true);
    } else {
      setPageType("profile");
    }
    const res = await dispatch(getUserProfileById(id));
    setUserInfo(res);
  };

  return (
    <>
      {pageType === "table" ? (
        <div
          className="content-wrapper container-home"
          style={{ paddingTop: 20 }}>
          <div className="container-xxl flex-grow-1 container-p-b">
            <div className="card mb-3 mb-md-4">
              <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
                <div className="flex-row d-flex align-items-center">
                  <h5 className="mb-0 medium text-nowrap">
                    Organizations{" "}
                    {(!isLoading || organization?.length) &&
                      `(${organization?.length})`}
                  </h5>
                  <div className="input-group ms-2 ms-md-4">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text border-0 h-100"
                        id="basic-addon1">
                        <i className="far fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0 ps-0"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
                <a
                  role="button"
                  onClick={() => setIsOpen(true)}
                  className="btn">
                  + New{" "}
                </a>
              </div>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap mb-4">
                  <table className="table table-borderless medium data-table-block">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Category</th>
                        <th>Members</th>
                        <th>Businesses</th>
                        <th>Status</th>
                        <th className="hide-arrow">Actions</th>
                      </tr>
                    </thead>

                    <tbody className="table-border-bottom-0 text-1e">
                      {showData?.map((org, i) => (
                        <tr key={i}>
                          <td>
                            <img
                              src={
                                org?.profilePic
                                  ? org?.profilePic
                                  : organizationPic
                              }
                              alt="Avatar"
                              className="w-px-30 h-px-30 rounded-circle me-2"
                            />
                            {org?.name}
                          </td>
                          <td>
                            {!org?.address ? "-" : ""} {org?.address} <br />{" "}
                            {org?.city}
                            {org?.address ? "," : ""} {org?.state}{" "}
                          </td>
                          <td>{org?.category ? org?.category : "-"}</td>
                          <td>{org?.totalMembers ? org?.totalMembers : 0}</td>
                          <td>
                            {org?.totalBusinesses ? org?.totalBusinesses : 0}
                          </td>
                          <td>
                            <span
                              class={`badge bg-label-${
                                org?.status === "approved"
                                  ? "success"
                                  : org?.status === "rejected"
                                  ? "danger"
                                  : "warning"
                              } `}>
                              {org?.status === "approved"
                                ? "Approved"
                                : org?.status === "rejected"
                                ? "Rejected"
                                : "Pending"}{" "}
                            </span>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                onClick={() => {
                                  setSelectedData(org.id);
                                  setIsComponentVisible(true);
                                }}
                                type="button"
                                className={`btn btn-outline dropdown-toggle hide-arrow ${
                                  selectedData === org?.id ? "show" : ""
                                }`}
                                data-bs-toggle="dropdown">
                                <i className="fas fa-ellipsis-h"></i>
                              </button>
                              <div
                                ref={ref}
                                className={`dropdown-menu dropdown-menu-top ${
                                  selectedData === org?.id && isComponentVisible
                                    ? "show"
                                    : ""
                                } `}>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => getDetails(org?.id)}>
                                  <i className="far fa-eye me-1"></i> View
                                  Profile
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => getDetails(org?.id, true)}>
                                  <i className="far fa-edit me-1"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    setDeleteUserShow(true);
                                    setSelectedData(null);
                                    setUserId(org);
                                  }}>
                                  <i className="far fa-trash-alt me-1"></i>{" "}
                                  Delete
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    setChangePasswordMember(true);
                                    setSelectedData(null);
                                    setUserId(org?.id);
                                  }}>
                                  <i className="far fa-lock-alt me-1"></i> Reset
                                  Password
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  target="_default"
                                  href={
                                    "mailto:" +
                                    org?.email +
                                    "?subject=Member Unite Connect"
                                  }
                                  // onClick={() => {
                                  //   setIsOpenUser(true);
                                  //   setUserId(org?.id);
                                  //   setShowName(org?.name);
                                  //   setSelectedData(null);
                                  // }}
                                >
                                  <i className="far fa-comment me-1"></i>{" "}
                                  Message
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && !organization?.length && (
                    <div className="loading-text">
                      <p>Loading...</p>
                    </div>
                  )}
                  {!isLoading && !organization?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )}
                </div>

                {pagination?.totalPages > 1 && (
                  <Pagination
                    pagination={pagination}
                    data={organization}
                  />
                )}
              </div>
            </div>
          </div>
          <AddBusiness
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            userType={2}
            closeModal={() => setIsOpen(false)}
          />
          <ConnectUser
            modalIsOpen={modalIsOpenUser}
            setIsOpen={setIsOpenUser}
            showName={showName}
            userId={userId}
            closeModal={() => setIsOpenUser(false)}
          />
          <AdminChangePassword
            modalIsOpen={changePasswordMember}
            setIsOpen={setChangePasswordMember}
            userId={userId}
            closeModal={() => setChangePasswordMember(false)}
          />
          <DeleteUserModal
            modalIsOpen={deleteUserShow}
            setIsOpen={setDeleteUserShow}
            userId={userId}
            userType={2}
            closeModal={() => setDeleteUserShow(false)}
          />
          <BussinessDetailsModal
            modalIsOpen={bussinessDetailsShow}
            setIsOpen={setBussinessDetailsShow}
            userInfo={userInfo}
            closeModal={() => setBussinessDetailsShow(false)}
          />
        </div>
      ) : (
        <OrganizationProfile
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setPageType={setPageType}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          getDetails={getDetails}
          history={history}
        />
      )}
    </>
  );
};

export default Organization;
