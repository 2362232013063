import React, { useEffect } from "react";
import { getLocalData } from "../utils/functions";
import { CHURCH_UNITE_URL, CHURCH_URL_PREFIX, CLUB_URL_PREFIX } from "../utils/config";

const PublicLayout = ({ children, history }) => {
  const token = getLocalData("token");

  useEffect(() => {
    const userType = getLocalData("userType");

    if (token) {
      userType == 4 ? 
      (window?.location?.href?.includes(CHURCH_UNITE_URL) ?
      history.push(`${CHURCH_URL_PREFIX}/profile`) : history.push(`${CLUB_URL_PREFIX}/profile`)) : 
      (window?.location?.href?.includes(CHURCH_UNITE_URL) ?
      history.push(`${CHURCH_URL_PREFIX}/dashboard`) : history.push(`${CLUB_URL_PREFIX}/dashboard`)) 
    }
    // eslint-disable-next-line
  }, [token]);

  return <>{children}</>;
};

export default PublicLayout;
