import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FourOFour from "./views/404";
import Login from "./views/auth/Login";
import Dashboard from "./views/dashboard";
import Signup from "./views/auth/Signup";
import ForgotPassword from "./views/auth/forgot-password";
// import PrivacyPolicy from "./views/auth/privacy-policy";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          path="/"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/churchunite/"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/clubunite/"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/login"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/clubunite/login"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/churchunite/login"
          render={({ history }) => <Login history={history} />}
          exact
        />
        <Route
          path="/churchunite/forgot-password"
          render={({ history }) => <ForgotPassword history={history} />}
          exact
        />
        <Route
          path="/clubunite/forgot-password"
          render={({ history }) => <ForgotPassword history={history} />}
          exact
        />

        {/* <Route
          path="/churchunite/privacy-policy"
          render={({ history }) => <PrivacyPolicy history={history} />}
          exact
        />
        <Route
          path="/clubunite/privacy-policy"
          render={({ history }) => <PrivacyPolicy history={history} />}
          exact
        /> */}
        <Route
          path="/forgot-password"
          render={({ history }) => <ForgotPassword history={history} />}
          exact
        />
        <Route
          path="/register"
          render={({ history }) => <Signup history={history} />}
          exact
        />
        <Route
          path="/churchunite/register"
          render={({ history }) => <Signup history={history} />}
          exact
        />
        <Route
          path="/clubunite/register"
          render={({ history }) => <Signup history={history} />}
          exact
        />
        <Route
          path="/dashboard"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/churchunite/dashboard"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/clubunite/dashboard"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/organization"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/clubunite/organization"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/churchunite/organization"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/members"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/clubunite/members"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/churchunite/members"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/businesses"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/clubunite/businesses"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/churchunite/businesses"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/connected-businesses"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/clubunite/connected-businesses"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/churchunite/connected-businesses"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/profile"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/churchunite/profile"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/clubunite/profile"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/ads"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/churchunite/ads"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route
          path="/clubunite/ads"
          render={({ history }) => <Dashboard history={history} />}
          exact
        />
        <Route render={({ history }) => <FourOFour history={history} />} />
      </Switch>
    </Router>
  );
};

export default App;
