import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "../forms/TextField";
import { getLocalData, inputValidator, stateOptions } from "../utils/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  checkEmail,
  getOrganizationOptions,
  registerUser,
} from "../store/actions/authAction";
import { getUsers } from "../store/actions/dashboardAction";
import { setUIKey } from "../store/actions/uiAction";
import moment from "moment";

const schema = yup.object({
  name: yup.string().trim().required("Username is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  orgId: yup.string().trim().required("Organization is Required"),
  address: yup.string().trim().required("Address is Required"),
  city: yup.string().trim().required("City is Required"),
  state: yup.string().trim().required("State is Required"),
  zip: yup
    .string()
    .trim()
    .required("Zip is Required")
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid Zip"),
  adminName: yup.string().trim().required("Contact Name is Required"),
  category: yup.string().trim().required("Category is Required"),
  // website: yup.string().trim().required("Website is Required"),
  description: yup.string().trim().required("Description is Required"),
});

const organizationSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  address: yup.string().trim().required("Address is Required"),
  city: yup.string().trim().required("City is Required"),
  state: yup.string().trim().required("State is Required"),
  zip: yup
    .string()
    .trim()
    .required("Zip is Required")
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid Zip"),
  adminName: yup.string().trim().required("Contact Name is Required"),
  category: yup.string().trim().required("Category is Required"),
  // website: yup.string().trim().required("Website is Required"),
  description: yup.string().trim().required("Description is Required"),
});

const AddBusinessFrom = ({ closeModal, userType }) => {
  const [formType, setFormType] = useState("first");
  const [passwordType, setPasswordType] = useState("password");

  const [isLoading, setIsLoading] = useState(false);

  const { error } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.dashboard);

  const userTypeLocal = getLocalData("userType");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationOptions());
  }, []);

  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userType == 2 ? organizationSchema : schema),
  });

  useEffect(() => {
    if (userInfo?.id && userTypeLocal == 2) setValue("orgId", userInfo.id);
  }, [userInfo, userTypeLocal]);

  const addHandler = async (e) => {
    e.preventDefault();

    const result = await trigger([
      "address",
      "city",
      "state",
      "zip",
      "orgId",
      "adminName",
      "category",
      "website",
      "description",
    ]);

    if (result) {
      const data = getValues();

      const payload = {
        ...data,
        category: data?.category,
        memberSince:
          userType === 2 ? "" : moment(new Date()).format("YYYY-MM-DD"),
        orgId: userType === 2 ? 0 : data?.orgId,
        interests: "",
        facebook: "",
        instagram: "",
        twitter: "",
        type: userType,
      };

      setIsLoading(true);
      const res = await dispatch(registerUser(payload, true));
      if (res.status === "success") {
        await dispatch(getUsers(userType));
        closeModal();
      }
      setIsLoading(false);
    }
  };

  const userNextHadler = async (e) => {
    e.preventDefault();

    const result = await trigger([
      "name",
      "email",
      "password",
      "phone",
      "orgId",
      "memberSince",
    ]);

    if (result) {
      dispatch(setUIKey("error", ""));
      setIsLoading(true);
      const res = await dispatch(checkEmail({ email: getValues("email") }));
      setIsLoading(false);

      if (res?.status === "success") {
        setFormType("second");
        dispatch(setUIKey("error", ""));
      } else {
        dispatch(setUIKey("error", "Email already exists"));
      }
    }
  };

  return (
    <div
      className={`authentication-wrapper ${
        formType === "first" ? "add-member-modal " : "add-buss-modal"
      }   ${userType === 2 && formType === "first" ? "add-org-modal" : ""} `}>
      <div className="form-wizard">
        <form className="position-relative">
          <div
            className={`d-flex authentication-bg ${
              formType === "first" ? "p-sm-5" : ""
            }  p-4 login-ss`}>
            {formType === "first" ? (
              <div className="form-block mobile-margin-top ">
                <TextField
                  errors={errors}
                  placeholder="Name"
                  register={register}
                  fieldName="name"
                />
                <TextField
                  errors={errors}
                  placeholder="Email"
                  register={register}
                  fieldName="email"
                />

                <TextField
                  inputType={passwordType}
                  errors={errors}
                  divClass="style-line mb-3 form-password-toggle"
                  placeholder="Password"
                  register={register}
                  fieldName="password"
                  icon={<i className="far fa-eye-slash"></i>}
                  viewPassword={() =>
                    setPasswordType(
                      passwordType === "password" ? "text" : "password"
                    )
                  }
                />

                <TextField
                  errors={errors}
                  placeholder="Phone"
                  register={register}
                  fieldName="phone"
                />
                {/* <div className="row mb-md-3"> */}
                {userType !== 2 && userTypeLocal == 1 && (
                  <>
                    <div className="style-line">
                      <select
                        className="form-select form-control form-control-sm"
                        onChange={(e) => setValue("orgId", +e.target.value)}>
                        <option value="" disabled selected>
                          Organization
                        </option>
                        {organizationOptions?.map((val) => (
                          <option value={val?.id} key={val?.id}>
                            {val?.name}
                          </option>
                        ))}
                      </select>
                      <span className="line"></span>
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}>
                        {errors?.["orgId"]?.message}
                      </p>
                    </div>
                    {/* <div className="col-md-6">
                        <TextField
                          errors={errors}
                          placeholder="Member Since"
                          register={register}
                          inputType="date"
                          fieldName="memberSince"
                        />
                      </div> */}
                  </>
                )}
                {/* </div> */}

                <button
                  onClick={userNextHadler}
                  disabled={isLoading}
                  className="btn w-100 mt-3 wizard-next-btn">
                  {isLoading ? "Processing" : "Next"}
                </button>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                    marginTop: 5,
                  }}>
                  {error && !isLoading ? error : ""}
                </p>
              </div>
            ) : (
              <div
                className="form-block mobile-margin-top"
                style={{ width: 500 }}>
                <div className="row mb-md-4">
                  <div className="col-md-6">
                    <TextField
                      errors={errors}
                      placeholder="Address"
                      register={register}
                      fieldName="address"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      errors={errors}
                      placeholder="City"
                      register={register}
                      fieldName="city"
                    />
                  </div>
                  <div className="col-md-6 style-line">
                    {/* <TextField
                      errors={errors}
                      placeholder="Admin Email"
                      register={register}
                      fieldName="adminEmail"
                    /> */}
                    <select
                      className="form-select form-control form-control-sm"
                      onChange={(e) => setValue("state", e.target.value)}>
                      <option value="" disabled selected>
                        State
                      </option>
                      {stateOptions?.map((val) => (
                        <option value={val.code}>{val.name}</option>
                      ))}
                    </select>
                    <span className="line"></span>
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "2px",
                      }}>
                      {errors?.["state"]?.message}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <TextField
                      errors={errors}
                      placeholder="Zip"
                      register={register}
                      fieldName="zip"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      errors={errors}
                      placeholder="Contact Name"
                      register={register}
                      fieldName="adminName"
                    />
                  </div>
                  <div className="col-md-6 style-line">
                    {/* <TextField
                      errors={errors}
                      placeholder="Admin Email"
                      register={register}
                      fieldName="adminEmail"
                    /> */}
                    <select
                      className="form-select form-control form-control-sm"
                      onChange={(e) => setValue("category", e.target.value)}>
                      <option value="" disabled selected>
                        Category
                      </option>
                      {userType === 3 ? (
                        <>
                          <option value="Coffee Shop">Coffee Shop</option>
                          <option value="Restaurant">Restaurant</option>
                          <option value="Car Wash">Car Wash</option>
                          <option value="Taxi Service">Taxi Service</option>
                        </>
                      ) : (
                        <>
                          <option value="Golf Club">Golf Club</option>
                          <option value="Church">Church</option>
                        </>
                      )}
                    </select>
                    <span className="line"></span>
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "2px",
                      }}>
                      {errors?.["category"]?.message}
                    </p>
                  </div>
                  {/* <div className="col-md-12">
                    <TextField
                      errors={errors}
                      placeholder="Website"
                      register={register}
                      fieldName="website"
                    />
                  </div> */}
                  <div className="col-md-12 mt-4">
                    <div className="style-line mb-3">
                      <textarea
                        style={{
                          border: "solid 1px lightgrey",
                          borderRadius: 10,
                          padding: 10,
                          width: 250,
                        }}
                        {...register(`description`)}
                        className="form-control h-auto text-area"
                        rows="8"
                        placeholder="Provide some information regarding your business"></textarea>
                      <span className="line"></span>
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}>
                        {errors?.["description"]?.message}
                      </p>
                    </div>
                  </div>
                </div>

                <button
                  role="button"
                  disabled={isLoading}
                  onClick={addHandler}
                  className="btn w-100 mb-3 wizard-next-btn">
                  {isLoading ? "Saving" : "Save"}
                </button>
                <div className="mb-3">
                  <div className="text-center">
                    <a
                      role="button"
                      onClick={() => setFormType("first")}
                      className="wizard-previous-btn">
                      Go Back
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBusinessFrom;
