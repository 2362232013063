import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "../forms/TextField";
import { getLocalData, inputValidator } from "../utils/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotAdminPassword,
  getOrganizationOptions,
  registerUser,
} from "../store/actions/authAction";
import { getUsers } from "../store/actions/dashboardAction";
import { setUIKey } from "../store/actions/uiAction";

const userSchema = yup.object({
  password: yup.string().trim().required("Password is Required"),
  confirmPassword: yup.string().trim().required("Confirm Password is Required"),
});

const AdminChangePasswordForm = ({ closeModal, userId }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeC, setPasswordTypeC] = useState("password");

  const [isLoading, setIsLoading] = useState(false);

  const { error } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.dashboard);

  const userType = getLocalData("userType");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationOptions());
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    if (userInfo?.id && userType == 2) setValue("orgId", userInfo.id);
  }, [userInfo, userType]);

  const addHandler = async (data) => {
    console.log(data);

    if (data.password !== data.confirmPassword) {
      dispatch(setUIKey("error", "Password does not match"));

      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);
      return false;
    }

    const payload = {
      id: userId,
      password: data.password,
    };

    setIsLoading(true);
    const res = await dispatch(forgotAdminPassword(payload));
    if (res.status === "success") {
      closeModal();
    }
    setIsLoading(false);
  };

  return (
    <div className="authentication-wrapper add-member-modal">
      <div className="form-wizard">
        <form className="position-relative" onSubmit={handleSubmit(addHandler)}>
          <div className={`d-flex authentication-bg p-sm-5 p-4 login-ss`}>
            <div className="form-block mobile-margin-top">
              <TextField
                inputType={passwordType}
                errors={errors}
                divClass="style-line mb-3 form-password-toggle forgat-pwd"
                placeholder="Password"
                register={register}
                fieldName="password"
                icon={<i className="far fa-eye-slash"></i>}
                viewPassword={() =>
                  setPasswordType(
                    passwordType === "password" ? "text" : "password"
                  )
                }
              />

              <TextField
                inputType={passwordTypeC}
                errors={errors}
                divClass="style-line mb-3 form-password-toggle forgat-pwd"
                placeholder="Confirm Password"
                register={register}
                fieldName="confirmPassword"
                icon={<i className="far fa-eye-slash"></i>}
                viewPassword={() =>
                  setPasswordTypeC(
                    passwordTypeC === "password" ? "text" : "password"
                  )
                }
              />

              <button
                type="submit"
                disabled={isLoading}
                className="btn w-100 mt-3 wizard-next-btn">
                {isLoading ? "Processing" : "Change Password"}
              </button>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  marginTop: 5,
                }}>
                {error && !isLoading ? error : ""}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminChangePasswordForm;
