import React from "react";
import { showDataPerPage } from "../../utils/functions";

const Pagination = ({ pagination, data }) => {
  return (
    <div className="row g-3 text-muted align-items-center">
      <div className="col-sm-12 col-md-6">
        <div className="dataTables_info">
          Showing {(pagination.current - 1) * showDataPerPage + 1} to{" "}
          {Math.min(pagination.current * showDataPerPage, data?.length)} of{" "}
          {data?.length} entries
        </div>
      </div>
      <div className="col-sm-12 col-md-6">
        <ul className="pagination justify-content-md-end align-items-center">
          <li
            className={`page-item prev ${
              pagination.current === 1 ? "disabled" : ""
            } `}>
            <a
              role="button"
              onClick={pagination.onPrevClick}
              className="page-link">
              <i
                className={`far fa-chevron-left  ${
                  pagination.current === 1 ? "disabled-icon" : ""
                }`}></i>
            </a>
          </li>
          {pagination.links.map((item, idx) => {
            if (item === "...") {
              return <li className="page-item">...</li>;
            } else {
              return (
                <li
                  className={`page-item ${
                    pagination.current === item ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() =>
                      pagination.current !== item && pagination.goToPage(item)
                    }
                    className="">
                    {item}
                  </a>
                </li>
              );
            }
          })}
          <li
            className={`page-item ${
              pagination.current === pagination.totalPages ? "disabled" : "next"
            }`}>
            <a
              role="button"
              onClick={pagination.onNextClick}
              className="page-link">
              <i
                className={`far fa-chevron-right  ${
                  pagination.current === pagination.totalPages
                    ? "disabled-icon"
                    : ""
                }`}></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
