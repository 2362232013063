// local config
// export const CHURCH_UNITE_URL = "http://localhost:3001/churchunite";
// export const CLUB_UNITE_URL = "http://localhost:3000/clubunite";
// export const CHURCH_URL_PREFIX = "/churchunite";
// export const CLUB_URL_PREFIX = "/clubunite"; 


//server config
export const CHURCH_UNITE_URL = "https://churchunite.com";
export const CLUB_UNITE_URL = "https://clubunite.com";
export const CHURCH_URL_PREFIX = "";
export const CLUB_URL_PREFIX = ""; 
