import React, { useEffect } from "react";
import organizationPic from "../assets/img/organization.png";
import { phoneFormat } from "../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationOptions } from "../store/actions/authAction";
import {CHURCH_UNITE_URL } from "../utils/config";

const BussinessDetails = ({ userInfo }) => {
  const dispatch = useDispatch();

  const { organizationOptions } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo?.type === 4) dispatch(getOrganizationOptions());
  }, [userInfo]);

  const getOrgName = (orgId) => {
    return organizationOptions?.find((org) => org?.id === orgId)?.name || "-";
  };

  return (
    <div className="buss-details-modal">
      <div className="col-md-12 img-center">
        <img
          className="buss-details-img"
          src={userInfo?.profilePic ? userInfo?.profilePic : organizationPic}
        />
        {userInfo?.bannerImage && (
          <img
            style={{
              paddingLeft: 20,
              height: 50,
              marginTop: "auto",
              marginBottom: "auto",
            }}
            width={250}
            className="buss-details-img"
            src={userInfo?.bannerImage}
          />
        )}
      </div>
      <div className="col-md-12 buss-add-box mt-4">
        <div style={{ fontSize: 20, fontWeight: 600 }}>
          {userInfo?.name ? userInfo?.name : ""}
        </div>
        {userInfo?.type === 4 && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512">
              <path
                  fill={window.location.href?.includes(CHURCH_UNITE_URL) ? "#134169" : "#006747"}
                d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z"
              />
            </svg>{" "}
            {getOrgName(userInfo?.orgId)}
          </div>
        )}
        <div>
          {userInfo?.address
            ? `${userInfo?.address}, ${userInfo?.city}, ${userInfo?.state}, ${userInfo?.zip}`
            : ""}
        </div>
      </div>
      <div className="col-md-12 mt-4">
        {userInfo?.type !== 4 && (
          <>
            <div style={{ fontSize: 14, padding: "2px 20px" }}>
              <span style={{ fontWeight: 600 }}> </span>{" "}
              {userInfo?.description ? userInfo?.description : ""}
            </div>
            <br />
            <div style={{ fontSize: 14, padding: "2px 20px" }}>
              <span style={{ fontWeight: 300 }}>Contact Name: </span>{" "}
              {userInfo?.adminName ? userInfo?.adminName : ""} (
              {userInfo?.contactTitle ? userInfo?.contactTitle : "Admin"})
            </div>
          </>
        )}

        {userInfo?.email && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <i
              className="fa fa-envelope icon-buss"
              aria-hidden="true"
            />{" "}
            {userInfo?.email}
          </div>
        )}
        {userInfo?.phone && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <i
              className="fa fa-phone icon-buss"
              aria-hidden="true"
            />{" "}
            {phoneFormat(userInfo?.phone)}
          </div>
        )}
        {userInfo?.type === 4 && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512">
              <path
                  fill={window.location.href?.includes(CHURCH_UNITE_URL) ? "#134169" : "#006747"}
                d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
              />
            </svg>{" "}
            {userInfo?.interests?.replaceAll(",", ", ")}
          </div>
        )}
        {userInfo?.website && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <i
              className="fa fa-globe icon-buss"
              aria-hidden="true"
            />{" "}
            {userInfo?.website}
          </div>
        )}
        {userInfo?.twitter && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512">
              <path
                  fill={window.location.href?.includes(CHURCH_UNITE_URL) ? "#134169" : "#006747"}
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              />
            </svg>{" "}
            {userInfo?.twitter}
          </div>
        )}
        {userInfo?.instagram && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512">
              <path
                  fill={window.location.href?.includes(CHURCH_UNITE_URL) ? "#134169" : "#006747"}
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>{" "}
            {userInfo?.instagram}
          </div>
        )}
        {userInfo?.facebook && (
          <div style={{ fontSize: 14, padding: "5px 20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512">
              <path
                  fill={window.location.href?.includes(CHURCH_UNITE_URL) ? "#134169" : "#006747"}
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
              />
            </svg>{" "}
            {userInfo?.facebook}
          </div>
        )}
      </div>
    </div>
  );
};

export default BussinessDetails;
