import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import AdminChangePasswordForm from "./admin-change-password-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewOrg,
  getAds,
  getCurrentUser,
  getOrgIds,
  getUsers,
} from "../store/actions/dashboardAction";
import {
  deleteAds,
  deleteUserAction,
  loginUser,
  loginUserByOrg,
} from "../store/actions/authAction";
import { CHURCH_UNITE_URL, CLUB_UNITE_URL } from "../utils/config";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    zIndex: 100000,
  },
};

const AddOrganisations = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  organizationOptions,
  getOrgName,
  history,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selecteOrg, setSelectedOrg] = useState({});
  const [pageType, setPageType] = useState("listing");
  const [successType, setSuccessType] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const [userData, setUserData] = useState([]);

  const { userInfo } = useSelector((state) => state.dashboard);

  useEffect(() => {
    getOrgIdsHandler();
  }, []);

  const getOrgIdsHandler = async () => {
    const res = await dispatch(getOrgIds(userInfo?.email));

    if (res) {
      setUserData(res);
    }
  };

  const checkShowButton = (id) => {
    return {
      join: !userData?.find((val) => val?.id === id)?.status,
      status: userData?.find((val) => val?.id === id)?.status,
    };
  };

  const saveHandle = async () => {
    setIsLoading(true);

    await dispatch(addNewOrg({ orgId: selecteOrg?.id }));
    await getOrgIdsHandler();

    setPageType("success");
    setSuccessType("join");
    setIsLoading(false);
  };

  const switchProfile = async (user) => {
    const payload = {
      orgId: user?.id,
      userId: user?.userId,
    };
    setSelectedId(user?.id);

    setIsLoading(true);
    const res = await dispatch(loginUserByOrg(payload));

    if (res.status === "success" && res?.isApproved) {
      window.location.replace(
        `${res.theme === "blue" ? CHURCH_UNITE_URL : CLUB_UNITE_URL}/${
          res.type === 4 ? "profile" : "dashboard"
        }?token=${res.token}`,
      );
    }

    await dispatch(getCurrentUser());
    setIsLoading(false);
    closeModal();
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="org-modal">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: 40,
              marginRight: 20,
              marginTop: 20,
              marginBottom: 20,
            }}>
            <p style={{ fontSize: 20, fontWeight: 500 }}>
              {pageType === "details" ? "Join" : "Select"} Organization
            </p>
            <p
              style={{
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 18,
                color: window.location.href?.includes(CHURCH_UNITE_URL)
                  ? "#134169"
                  : "#006747",
                zIndex: 10000,
              }}
              role="button"
              onClick={closeModal}>
              X
            </p>
          </div>

          {pageType === "listing" ? (
            <div className="form-wizard">
              {userData?.map((val) => (
                <div className="row">
                  <div className="col-8">
                    <p
                      style={{
                        fontWeight: 600,
                        color: "#333",
                        fontSize: 16,
                        marginBottom: 10,
                      }}
                      className="form-check-label"
                      htmlFor={`check-${val?.id}`}>
                      {val?.name}{" "}
                    </p>
                  </div>

                  <div className="col-4 row">
                    {checkShowButton(val?.id).join && (
                      <a
                        role="button"
                        className="col-md-12"
                        style={{ fontWeight: 600, padding: 0 }}
                        onClick={() => {
                          setPageType("details");
                          setSelectedOrg(val);
                        }}>
                        Join
                      </a>
                    )}

                    {checkShowButton(val?.id)?.status === "approved" &&
                      userInfo?.orgId === val?.id && (
                        <p
                          className="col-md-12"
                          style={{
                            fontWeight: 600,
                            padding: 0,
                            color: "#333",
                          }}>
                          Logged In
                        </p>
                      )}

                    {checkShowButton(val?.id)?.status === "approved" &&
                      userInfo?.orgId !== val?.id && (
                        <a
                          role="button"
                          className="col-md-12"
                          disabled={isLoading}
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            padding: 0,
                          }}
                          onClick={() => switchProfile(val)}>
                          {isLoading && selectedId === val?.id
                            ? "Switching"
                            : "Switch"}
                        </a>
                      )}

                    {checkShowButton(val?.id)?.status === "pending" && (
                      <p
                        className="col-md-12"
                        style={{
                          fontWeight: 600,
                          color: "#333",
                          padding: 0,
                        }}>
                        Approval Pending
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : pageType === "success" ? (
            <>
              <p
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#333",
                  padding: 10,
                  textAlign: "center",
                }}>
                {successType === "switch"
                  ? `Switched to organization
                ${getOrgName(userInfo?.orgId)}.`
                  : `Your request to join the organization has been submitted.`}
              </p>
              <button
                onClick={() => {
                  closeModal();
                }}
                style={{
                  width: "90%",
                  marginLeft: "5%",
                  backgroundColor: window.location.href?.includes(
                    CHURCH_UNITE_URL,
                  )
                    ? "#134169"
                    : "#006747",
                }}
                className="btn  mt-2 wizard-next-btn">
                Done
              </button>
            </>
          ) : (
            <div
              className="form-wizard"
              style={{ paddingLeft: 20 }}>
              <p className="details-label">
                Organization Name: {console.log(selecteOrg)}
                <span style={{ fontWeight: 600 }}>{selecteOrg?.name}</span>
              </p>
              <div
                className="row"
                style={{
                  width: "100%",
                  marginTop: "-20px",
                  display: "flex",
                  marginBottom: "30px",
                  justifyContent: "center",
                }}>
                <div className="col-md-12">
                  <button
                    onClick={() => setPageType("listing")}
                    style={{ marginRight: 10, width: "48%" }}
                    className="btn  mt-5 wizard-next-btn grey-btn">
                    Back
                  </button>
                  <button
                    onClick={saveHandle}
                    style={{ width: "48%" }}
                    disabled={isLoading}
                    className="btn mt-5 wizard-next-btn">
                    {isLoading ? "Joining" : "Join"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AddOrganisations;
