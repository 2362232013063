import React, { useEffect, useState } from "react";
import organizationPic from "../../assets/img/organization.png";
import { useDispatch, useSelector } from "react-redux";
import { getAds, getUsers } from "../../store/actions/dashboardAction";
import moment from "moment";
import { getLocalData, showDataPerPage } from "../../utils/functions";
import { useComponentVisible, usePagination } from "../../apis/hooks";
import Pagination from "./Pagination";
import OrganizationProfile from "./organization-profile";
import {
  getOrganizationOptions,
  getUserProfileById,
} from "../../store/actions/authAction";
import AddAds from "../../modals/add-ads";
import ConnectUser from "../../modals/connect-user";
import DeleteUserModal from "../../modals/delete-user";

const Ads = ({ history }) => {
  const dispatch = useDispatch();
  const [showData, setShowData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [pageType, setPageType] = useState("table");
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { ads } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);
  const userType = getLocalData("userType");

  useEffect(() => {
    dispatch(getAds());
    dispatch(getOrganizationOptions());
  }, []);

  const pagination = usePagination(ads?.length, showDataPerPage);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  useEffect(() => {
    if (ads?.length && pagination.current > 0) {
      setShowData(
        ads?.slice(
          (pagination.current - 1) * showDataPerPage,
          pagination.current * showDataPerPage,
        ),
      );
    }
  }, [ads, pagination.current]);

  useEffect(() => {
    setShowData(
      ads?.filter((ad) =>
        ad?.title?.toLowerCase()?.includes(searchText?.toLocaleLowerCase()),
      ),
    );
  }, [ads, searchText]);

  const getDetails = async (id, isEdit) => {
    if (isEdit) {
      setEditProfile(true);
    } else {
      setEditProfile(false);
    }
    setSelectedData(null);
    setPageType("profile");
    const res = ads.find((ad) => ad.id === id);

    setUserInfo(res);
  };

  const getOrgName = (orgId) => {
    return organizationOptions?.find((org) => org?.id === orgId)?.name || "-";
  };

  return (
    <>
      {pageType === "table" ? (
        <div
          className="content-wrapper container-home"
          style={{ paddingTop: 20 }}>
          <div className="container-xxl flex-grow-1 container-p-b">
            <div className="card mb-3 mb-md-4">
              <div className="card-header flex-row d-md-flex align-items-center justify-content-between">
                <div className="flex-row d-flex align-items-center">
                  <h5 className="mb-0 medium text-nowrap">
                    Ads{" "}
                    {(!isLoading || ads?.length) && `(${ads?.length || "0"})`}
                  </h5>
                  <div className="input-group ms-2 ms-md-4 org-search">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text border-0 h-100"
                        id="basic-addon1">
                        <i className="far fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="form-control border-0 ps-0"
                      placeholder="Search"
                    />
                  </div>
                </div>

                <a
                  role="button"
                  onClick={() => setIsOpen(true)}
                  className="btn">
                  + New{" "}
                </a>
              </div>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap mb-4">
                  <table className="table table-borderless medium data-table-block">
                    <thead>
                      <tr>
                        <th>Banner</th>
                        <th>Title</th>
                        <th>Business</th>
                        {userType == 1 && <th>Organization</th>}
                        <th>Date</th>
                        <th>Clicks</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 text-1e">
                      {showData?.map((ad) => (
                        <tr key={ad?.id}>
                          <td>
                            <img
                              src={
                                ad?.imageUrl ? ad?.imageUrl : organizationPic
                              }
                              alt="Avatar"
                              className="w-px-40 h-auto ads-list-pic me-2"
                            />
                            {ad?.name}
                          </td>
                          <td>{ad.title}</td>
                          <td>{ad?.businessName}</td>
                          {userType == 1 && <td>{getOrgName(ad?.orgId)}</td>}
                          <td>{moment(ad.createdDate).format("MM/DD/YYYY")}</td>
                          <td>{ad?.clicks}</td>
                          <td>
                            <span
                              class={`badge bg-label-${
                                ad?.status === 1
                                  ? "success"
                                  : ad?.status === -1
                                  ? "danger"
                                  : "warning"
                              } `}>
                              {ad?.status === 1
                                ? "Approved"
                                : ad?.status === -1
                                ? "Rejected"
                                : "Pending"}{" "}
                            </span>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                onClick={() => {
                                  setSelectedData(ad.id);
                                  setIsComponentVisible(true);
                                }}
                                type="button"
                                className={`btn btn-outline dropdown-toggle hide-arrow ${
                                  selectedData === ad?.id ? "show" : ""
                                }`}
                                data-bs-toggle="dropdown">
                                <i className="fas fa-ellipsis-h"></i>
                              </button>
                              <div
                                ref={ref}
                                className={`dropdown-menu dropdown-menu-top-ads ${
                                  selectedData === ad?.id && isComponentVisible
                                    ? "show"
                                    : ""
                                } `}>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => getDetails(ad?.id)}>
                                  <i className="far fa-eye me-1"></i> View
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => getDetails(ad?.id, true)}>
                                  <i className="far fa-edit me-1"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => {
                                    setDeleteUserShow(true);
                                    setSelectedData(null);
                                    setUserId(ad?.id);
                                  }}>
                                  <i className="far fa-trash-alt me-1"></i>{" "}
                                  Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && !ads?.length && (
                    <div className="loading-text">
                      <p>Loading...</p>
                    </div>
                  )}
                  {!isLoading && !ads?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )}
                </div>

                {pagination?.totalPages > 1 && (
                  <Pagination
                    pagination={pagination}
                    data={ads}
                  />
                )}
              </div>
            </div>
          </div>
          <AddAds
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            closeModal={() => setIsOpen(false)}
          />
          <DeleteUserModal
            modalIsOpen={deleteUserShow}
            setIsOpen={setDeleteUserShow}
            userId={userId}
            ads={true}
            closeModal={() => setDeleteUserShow(false)}
          />
        </div>
      ) : (
        <OrganizationProfile
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setPageType={setPageType}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          getDetails={getDetails}
          ads={true}
          history={history}
        />
      )}
    </>
  );
};

export default Ads;
