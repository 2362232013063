import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "../forms/TextField";
import { getLocalData, inputValidator } from "../utils/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationOptions,
  registerUser,
} from "../store/actions/authAction";
import { getUsers } from "../store/actions/dashboardAction";
import moment from "moment";

const userSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  orgId: yup.string().trim().required("Organization is Required"),
});

const AddMemberFrom = ({ closeModal }) => {
  const [passwordType, setPasswordType] = useState("password");

  const [isLoading, setIsLoading] = useState(false);

  const { error } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);
  const { userInfo } = useSelector((state) => state.dashboard);

  const userType = getLocalData("userType");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationOptions());
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    if (userInfo?.id && userType == 2) setValue("orgId", userInfo.id);
  }, [userInfo, userType]);

  const addHandler = async (data) => {
    const payload = {
      ...data,
      category: "",
      memberSince: moment(new Date()).format("YYYY-MM-DD"),
      orgId: data?.orgId,
      interests: "",
      facebook: "",
      instagram: "",
      twitter: "",
      type: 4,
    };

    setIsLoading(true);
    const res = await dispatch(registerUser(payload, true));
    if (res.status === "success") {
      await dispatch(getUsers(4));
      closeModal();
    }
    setIsLoading(false);
  };

  return (
    <div className="authentication-wrapper add-member-modal">
      <div className="form-wizard">
        <form className="position-relative" onSubmit={handleSubmit(addHandler)}>
          <div className={`d-flex authentication-bg p-sm-5 p-4 login-ss`}>
            <div className="form-block mobile-margin-top">
              <TextField
                errors={errors}
                placeholder="Name"
                register={register}
                fieldName="name"
              />
              <TextField
                errors={errors}
                placeholder="Email"
                register={register}
                fieldName="email"
              />

              <TextField
                inputType={passwordType}
                errors={errors}
                divClass="style-line mb-3 form-password-toggle"
                placeholder="Password"
                register={register}
                fieldName="password"
                icon={<i className="far fa-eye-slash"></i>}
                viewPassword={() =>
                  setPasswordType(
                    passwordType === "password" ? "text" : "password"
                  )
                }
              />

              <TextField
                errors={errors}
                placeholder="Phone"
                register={register}
                fieldName="phone"
              />
              {userType == 1 && (
                <div className="style-line">
                  <select
                    className="form-select form-control form-control-sm"
                    onChange={(e) => setValue("orgId", +e.target.value)}>
                    <option value="" disabled selected>
                      Organization
                    </option>
                    {organizationOptions?.map((val) => (
                      <option value={val?.id} key={val?.id}>
                        {val?.name}
                      </option>
                    ))}
                  </select>
                  <span className="line"></span>
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "2px",
                    }}>
                    {errors?.["orgId"]?.message}
                  </p>
                </div>
              )}

              {/* <div className="row mb-md-5"> */}
              {/* <div className="col-md-6">
                  <TextField
                    errors={errors}
                    placeholder="Member Since"
                    register={register}
                    inputType="date"
                    fieldName="memberSince"
                  />
                </div> */}
              {/* </div> */}

              <button
                type="submit"
                disabled={isLoading}
                className="btn w-100 mt-3 wizard-next-btn">
                {isLoading ? "Saving" : "Save"}
              </button>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  marginTop: 5,
                }}>
                {error && !isLoading ? error : ""}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMemberFrom;
