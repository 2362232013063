import {
  URL,
  GET_USERS,
  GET_ADS,
  UPLOAD_PROFILE,
  ADS_UPDATE,
  UPLOAD_BANNER,
  CONNECTED_BUSINESS,
  GET_DISPLAY_ADS,
} from "../../apis/routes";
import ApiService from "../../services/ApiServices";
import { SET_DASHBOARD_DATA } from "../constants/dashboardConstants";
import { setUIKey } from "./uiAction";

export const setDashboardData = (data) => ({
  type: SET_DASHBOARD_DATA,
  payload: data,
});

export const getUsers =
  (type = 2) =>
  async (dispatch) => {
    try {
      dispatch(setUIKey("isLoading", true));
      const res = await ApiService.get(`${URL}/${GET_USERS}/${type}`);
      dispatch(setUIKey("isLoading", false));

      if (res?.data) {
        if (type === 2) dispatch(setDashboardData({ organization: res.data }));
        if (type === 3) dispatch(setDashboardData({ businesses: res.data }));
        if (type === 4) dispatch(setDashboardData({ members: res.data }));
      }

      return Promise.resolve(res.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

export const deleteAccount = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/user/delete`);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/${GET_USERS}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ userInfo: res.data }));
      localStorage.setItem("userType", res?.data?.type);
      localStorage.setItem("userName", res?.data?.name);
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getAds = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_ADS}`);

    if (res?.data) {
      dispatch(setDashboardData({ ads: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getConnectedBusiness = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${CONNECTED_BUSINESS}`);

    if (res?.data) {
      dispatch(setDashboardData({ connectedBussiness: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getOrgIds = (email) => async () => {
  try {
    const res = await ApiService.get(`${URL}/user/org-ids/${email}`);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDisplayAds = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_DISPLAY_ADS}`);

    if (res?.data) {
      dispatch(setDashboardData({ displayAds: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addNewAd = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${GET_ADS}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addNewOrg = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/user/save-org`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateAds = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${ADS_UPDATE}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateClicks = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/ad/increase-clicks`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateProfilePhoto = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.postFormData(`${URL}/${UPLOAD_PROFILE}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateAdsPhoto = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.postFormData(`${URL}/${UPLOAD_BANNER}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
