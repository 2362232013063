import React, { useEffect, useState } from "react";
import defaultPic from "../../assets/img/profile-default.png";
import { getLocalData } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  setDashboardData,
} from "../../store/actions/dashboardAction";
import { useComponentVisible } from "../../apis/hooks";

const Header = ({ history, showSidebar, setShowSidebar }) => {
  const [showDropDown, setShowDropdown] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.dashboard);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getCurrentUser());
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    window.location.reload();
    // history.push("/login");
    setShowDropdown(false);
  };

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar "
      style={{ padding: 0, backgroundColor: "transparent" }}>
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a
          className="nav-item nav-link p-0 me-xl-4"
          role="button"
          onClick={() => setShowSidebar(true)}>
          <i className="fal fa-bars fa-2x"></i>
        </a>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center justify-content-end"
        id="navbar-collapse">
        <div className="col-md-4 col-6 d-block d-xl-none">
          <div className="">
            <a
              role="button"
              className="app-brand-link h2 mb-0 primary-color bold">
              <em>
                {window.location.pathname?.includes("churchunite")
                  ? "CHURCHUNITE"
                  : "CLUBUNITE"}
              </em>
            </a>
          </div>
        </div>
        <h5
          className="col-md-4 col-6 semi tagline mb-0 header-tagline"
          style={{ backgroundColor: "" }}>
          MEMBER &bull; CONNECT &bull; COMMERCE
        </h5>
        <ul
          className="col-md-4 col-6 profile-menu navbar-nav flex-row align-items-center justify-content-end"
          style={{ backgroundColor: "" }}>
          {/* <li className="nav-item navbar-dropdown dropdown-notification dropdown">
            <a
              className={`nav-link dropdown-toggle hide-arrow ${notificationDropdown ? "show" : ""
                }`}
              role="button"
              onClick={() => {
                setShowDropdown(false);
                setNotificationDropdown(!notificationDropdown);
              }}
              data-bs-toggle="dropdown">
              <i className="far fa-bell"></i>
            </a>
            <ul
              className={`dropdown-menu dropdown-menu-end py-0 notification-drop ${notificationDropdown ? "show" : ""
                }`}>
              <li className="dropdown-menu-header border-bottom">
                <div className="dropdown-header d-flex align-items-center py-3">
                  <h5 className="text-body mb-0 me-auto">Notification</h5>
                  <div className="dropdown-notifications-all text-body">
                    <i className="bx fs-4 bx-envelope-open"></i>
                  </div>
                </div>
              </li>
              <li className="dropdown-notifications-list message-center theme-scrollbar">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item list-group-item-action dropdown-notifications-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar">
                          <span className="avatar-initial rounded-circle bg-label-primary">
                            CF
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1 medium">Charles Franklin</h6>
                        <p className="mb-0 small">Accepted your connection</p>
                        <small className="text-muted">12hr ago</small>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item list-group-item-action dropdown-notifications-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar">
                          <span className="avatar-initial rounded-circle bg-label-danger">
                            CF
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1 medium">Charles Franklin</h6>
                        <p className="mb-0 small">Accepted your connection</p>
                        <small className="text-muted">12hr ago</small>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item list-group-item-action dropdown-notifications-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar">
                          <span className="avatar-initial rounded-circle bg-label-success">
                            CF
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1 medium">Charles Franklin</h6>
                        <p className="mb-0 small">Accepted your connection</p>
                        <small className="text-muted">12hr ago</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="dropdown-menu-footer border-top">
                <a
                  href="javascript:void(0);"
                  className="dropdown-item d-flex justify-content-center p-3">
                  View all notifications
                </a>
              </li>
            </ul>
          </li> */}
          <li
            className="nav-item navbar-dropdown dropdown-user dropdown ms-xl-4 ms-3"
            style={{ backgroundColor: "", padding: 0, marginRight: 0 }}>
            <a
              role="button"
              className={`nav-link semi text-1ee d-flex align-items-center dropdown-toggle user-name ${
                isComponentVisible ? "show" : ""
              }`}
              onClick={() => {
                setShowDropdown(true);
                setNotificationDropdown(false);
                setIsComponentVisible(!isComponentVisible);
              }}
              data-bs-toggle="dropdown">
              <div className="avatar me-2">
                <img
                  src={userInfo?.profilePic ? userInfo?.profilePic : defaultPic}
                  alt
                  className="w-px-40 rounded-circle"
                  style={{ width: "30px", height: "30px" }}
                />
              </div>
              {localStorage.getItem("userName")}
            </a>
            <ul
              ref={ref}
              className={`dropdown-menu dropdown-menu-end user-dropdown ${
                isComponentVisible && showDropDown ? "show" : ""
              }`}>
              {/* <li>
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={() => history.push("/")}>
                  <i className="far fa-user me-2"></i>
                  <span className="align-middle">My Profile</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={() => history.push("/")}>
                  <i className="far fa-cog me-2"></i>
                  <span className="align-middle">Settings</span>
                </a>
              </li> */}
              <li>
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={logoutHandler}>
                  <i className="far fa-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
