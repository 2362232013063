import React, { useEffect, useState } from "react";
import organizationPic from "../../assets/img/organization.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getConnectedBusiness,
  getDisplayAds,
  getUsers,
  updateClicks,
} from "../../store/actions/dashboardAction";
import moment from "moment";
import { getLocalData, showDataPerPage } from "../../utils/functions";
import { useComponentVisible, usePagination } from "../../apis/hooks";
import Pagination from "./Pagination";
import AddBusiness from "../../modals/add-businesses";
import OrganizationProfile from "./organization-profile";
import {
  getOrganizationOptions,
  getUserProfileById,
} from "../../store/actions/authAction";
import ConnectUser from "../../modals/connect-user";
import AdminChangePassword from "../../modals/admin-change-password";
import DeleteUserModal from "../../modals/delete-user";
import BussinessDetailsModal from "../../modals/bussiness-details-modal";
import DispalyAds from "../../modals/dispaly-ads";

const Buisnesses = ({ history, connected }) => {
  const dispatch = useDispatch();
  const [showData, setShowData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenUser, setIsOpenUser] = useState(false);
  const [showName, setShowName] = useState(null);
  const [changePasswordMember, setChangePasswordMember] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [bussinessDetailsShow, setBussinessDetailsShow] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [pageType, setPageType] = useState("table");
  const [userInfo, setUserInfo] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [closeAds, setCloseAds] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");

  const {
    businesses,
    userInfo: logedInUser,
    displayAds,
    connectedBussiness,
  } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);

  const userType = getLocalData("userType");

  useEffect(() => {
    dispatch(getUsers(3));
    dispatch(getOrganizationOptions());
    if (userType == 4 || userType == 3) dispatch(getDisplayAds());
    if (connected) dispatch(getConnectedBusiness());
  }, []);

  const pagination = usePagination(
    connected
      ? connectedBussiness?.filter((bussniess) =>
          bussniess?.id !== logedInUser?.id && selectedOrg
            ? bussniess?.orgId === selectedOrg
            : bussniess,
        )?.length
      : businesses?.filter((bussniess) =>
          bussniess?.id !== logedInUser?.id && selectedOrg
            ? bussniess?.orgId === selectedOrg
            : bussniess,
        )?.length,
    showDataPerPage,
  );

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  useEffect(() => {
    if (connected) {
      if (connectedBussiness?.length && pagination.current > 0) {
        setShowData(
          connectedBussiness
            ?.filter((buss) =>
              buss?.name
                ?.toLowerCase()
                ?.includes(searchText?.toLocaleLowerCase()),
            )
            ?.filter((bussniess) =>
              bussniess?.id !== logedInUser?.id && selectedOrg
                ? bussniess?.orgId === selectedOrg
                : bussniess,
            )
            ?.slice(
              (pagination.current - 1) * showDataPerPage,
              pagination.current * showDataPerPage,
            ),
        );
      }
    } else {
      if (businesses?.length && pagination.current > 0) {
        setShowData(
          businesses
            ?.filter((buss) =>
              buss?.name
                ?.toLowerCase()
                ?.includes(searchText?.toLocaleLowerCase()),
            )
            ?.filter((bussniess) =>
              bussniess?.id !== logedInUser?.id && selectedOrg
                ? bussniess?.orgId === selectedOrg
                : bussniess,
            )
            ?.slice(
              (pagination.current - 1) * showDataPerPage,
              pagination.current * showDataPerPage,
            ),
        );
      }
    }
  }, [businesses, pagination.current, connected, searchText, selectedOrg]);

  const getOrgName = (orgId) => {
    return organizationOptions?.find((org) => org?.id === orgId)?.name || "-";
  };

  const getDetails = async (id, isEdit, buss) => {
    setUserInfo(null);
    if (isEdit) {
      setEditProfile(true);
    } else {
      setEditProfile(false);
    }
    setSelectedData(null);
    if (!isEdit) {
      setBussinessDetailsShow(true);
    } else {
      setPageType("profile");
    }
    const res = await dispatch(getUserProfileById(id));
    setUserInfo(res);
  };

  const increaseClicksHandler = async (ads) => {
    if (ads?.webLink) window.open(ads?.webLink);

    const payload = {
      id: ads?.id,
      clicks: ads?.clicks + 1,
    };

    await dispatch(updateClicks(payload));
    dispatch(getDisplayAds());
  };

  return (
    <>
      {pageType === "table" ? (
        <div
          className="content-wrapper container-home"
          style={{ paddingTop: 20 }}>
          <div className="container-xxl flex-grow-1 container-p-b">
            <div className="card mb-3 mb-md-4">
              <div className="card-header flex-row d-md-flex align-items-center justify-content-between row">
                <div className="flex-row d-flex align-items-center col-md-8 row">
                  <h5 className="mb-0 medium text-nowrap col-md-3">
                    {connected ? "Connected" : ""} Businesses{" "}
                    {connected
                      ? (!isLoading || connectedBussiness?.length) &&
                        `(${connectedBussiness?.length || 0})`
                      : (!isLoading || businesses?.length) &&
                        `(${businesses?.length || 0})`}
                  </h5>
                  <div className="col-md-5 search-box">
                    <div className="input-group ms-2 ms-md-4 org-search">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text border-0 h-100"
                          id="basic-addon1">
                          <i className="far fa-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control border-0 ps-0"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                  </div>
                  {userType == 1 && (
                    <div className="style-line col-md-4 filter-box">
                      <select
                        style={{
                          border: "1px solid #d9dee3",
                          borderRadius: "0.375rem",
                          marginLeft: 10,
                        }}
                        className="form-select form-control form-control-sm"
                        onChange={(e) => {
                          setSelectedOrg(+e.target.value);
                          pagination.goToPage(1);
                        }}>
                        <option
                          value=""
                          selected>
                          All Organizations
                        </option>
                        {organizationOptions?.map((val) => (
                          <option
                            value={val?.id}
                            key={val?.id}
                            selected={val?.id === userInfo?.orgId}>
                            {val?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                {(userType == 2 || userType == 1) && (
                  <div className="col-md-2 add-button">
                    <a
                      role="button"
                      onClick={() => setIsOpen(true)}
                      className="btn">
                      + New{" "}
                    </a>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap mb-4">
                  <table className="table table-borderless medium data-table-block">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>
                          {userType == 3 || userType == 1
                            ? "Organization"
                            : "Address"}{" "}
                        </th>
                        {(userType == 2 || userType == 1) && (
                          <th>Signup Date</th>
                        )}
                        <th>{userType == 3 ? "Member Since" : "Business"} </th>
                        <th>
                          {userType == 2 || userType == 1
                            ? "Total Ads"
                            : userType == 3
                            ? "Connected"
                            : "Last Connected"}{" "}
                        </th>
                        {(userType == 2 || userType == 1) && <th>Status</th>}
                        {(userType == 2 || userType == 1) && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 text-1e">
                      {showData?.map((business, i) => (
                        <>
                          <tr key={i}>
                            <td>
                              <img
                                src={
                                  business?.profilePic
                                    ? business?.profilePic
                                    : organizationPic
                                }
                                alt="Avatar"
                                className="w-px-30 h-px-30 rounded-circle me-2"
                              />
                              {business?.name}
                            </td>
                            {userType == 3 || userType == 1 ? (
                              <td>{getOrgName(business?.orgId)}</td>
                            ) : (
                              <td>
                                {!business?.address ? "-" : ""}{" "}
                                {business?.address} <br /> {business?.city}
                                {business?.address ? "," : ""} {business?.state}{" "}
                              </td>
                            )}
                            {(userType == 2 || userType == 1) && (
                              <td>
                                {business?.memberSince
                                  ? moment(business?.memberSince).format(
                                      "MM/DD/YYYY",
                                    )
                                  : moment().format("MM/DD/YYYY")}
                              </td>
                            )}
                            <td>
                              {userType == 3
                                ? business?.memberSince
                                  ? moment(business?.memberSince).format(
                                      "MM/DD/YYYY",
                                    )
                                  : moment().format("MM/DD/YYYY")
                                : business?.category
                                ? business?.category
                                : "-"}
                            </td>
                            <td>
                              {userType == 2 || userType == 1
                                ? business?.totalAds
                                : business?.lastLogin
                                ? moment(business?.lastLogin).format(
                                    "MM/DD/YYYY",
                                  )
                                : "Never"}
                            </td>
                            {(userType == 2 || userType == 1) && (
                              <td>
                                <span
                                  class={`badge bg-label-${
                                    business?.status === "approved"
                                      ? "success"
                                      : business?.status === "rejected"
                                      ? "danger"
                                      : "warning"
                                  } `}>
                                  {business?.status === "approved"
                                    ? "Approved"
                                    : business?.status === "rejected"
                                    ? "Rejected"
                                    : "Pending"}{" "}
                                </span>
                              </td>
                            )}
                            {userType == 2 || userType == 1 ? (
                              <td>
                                <div className="dropdown">
                                  <button
                                    onClick={() => {
                                      setSelectedData(business.id);
                                      setIsComponentVisible(true);
                                    }}
                                    type="button"
                                    className={`btn btn-outline dropdown-toggle hide-arrow ${
                                      selectedData === business?.id
                                        ? "show"
                                        : ""
                                    }`}
                                    data-bs-toggle="dropdown">
                                    <i className="fas fa-ellipsis-h"></i>
                                  </button>
                                  <div
                                    ref={ref}
                                    className={`dropdown-menu dropdown-menu-top ${
                                      selectedData === business?.id &&
                                      isComponentVisible
                                        ? "show"
                                        : ""
                                    } `}>
                                    <a
                                      className="dropdown-item"
                                      role="button"
                                      onClick={() =>
                                        userType == 1
                                          ? getDetails(business?.id)
                                          : getDetails(
                                              business?.id,
                                              false,
                                              true,
                                            )
                                      }>
                                      <i className="far fa-eye me-1"></i> View
                                      Profile
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      role="button"
                                      onClick={() =>
                                        getDetails(business?.id, true)
                                      }>
                                      <i className="far fa-edit me-1"></i> Edit
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      role="button"
                                      onClick={() => {
                                        setDeleteUserShow(true);
                                        setSelectedData(null);
                                        setUserId(business);
                                      }}>
                                      <i className="far fa-trash-alt me-1"></i>{" "}
                                      Delete
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      role="button"
                                      onClick={() => {
                                        setChangePasswordMember(true);
                                        setSelectedData(null);
                                        setUserId(business?.id);
                                      }}>
                                      <i className="far fa-lock-alt me-1"></i>{" "}
                                      Reset Password
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      role="button"
                                      target="_default"
                                      href={
                                        "mailto:" +
                                        business?.email +
                                        "?subject=Member Unite Connect"
                                      }
                                      // onClick={() => {
                                      //   setIsOpenUser(true);
                                      //   setUserId(business?.id);
                                      //   setShowName(business?.name);
                                      //   setSelectedData(null);
                                      // }}
                                    >
                                      <i className="far fa-comment me-1"></i>{" "}
                                      Message
                                    </a>
                                  </div>
                                </div>
                              </td>
                            ) : (
                              (userType == 3 || userType == 4) && (
                                <td>
                                  {connected ? (
                                    <a
                                      href={
                                        "mailto:" +
                                        business?.email +
                                        "?subject=Member Unite Connect"
                                      }
                                      className="btn table-btn">
                                      Message
                                    </a>
                                  ) : (
                                    <a
                                      role="button"
                                      onClick={() => {
                                        setUserId(null);
                                        setBusinessId(business?.id);
                                        setShowName(business?.name);
                                        setIsOpenUser(true);
                                      }}
                                      className="btn table-btn">
                                      Connect
                                    </a>
                                  )}
                                  <br />
                                  <a
                                    role="button"
                                    style={{
                                      marginTop: "10px",
                                      paddingLeft: "16px",
                                      fontSize: 10,
                                      textAlign: "center",
                                    }}
                                    onClick={() =>
                                      getDetails(business?.id, false, true)
                                    }>
                                    View Profile
                                  </a>
                                </td>
                              )
                            )}
                          </tr>
                          {(i + 1) % 2 === 0 &&
                            displayAds?.filter(
                              (val) => val?.orgId === logedInUser?.orgId,
                            )?.[(i - 1) / 2]?.imageUrlWeb &&
                            (userType == 4 || userType == 3) && (
                              <tr>
                                <td colSpan={7}>
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <a
                                      onClick={() =>
                                        increaseClicksHandler(
                                          displayAds?.filter(
                                            (val) =>
                                              val?.orgId === logedInUser?.orgId,
                                          )[(i - 1) / 2],
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        width: "100%",
                                      }}>
                                      <img
                                        style={{
                                          width: "100%",
                                        }}
                                        src={
                                          displayAds?.filter(
                                            (val) =>
                                              val?.orgId === logedInUser?.orgId,
                                          )[(i - 1) / 2]?.imageUrlWeb
                                        }
                                        alt="Avatar"
                                      />
                                    </a>
                                  </div>
                                  <br />
                                </td>
                              </tr>
                            )}
                        </>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && !businesses?.length && (
                    <div className="loading-text">
                      <p>Loading...</p>
                    </div>
                  )}
                  {!isLoading && !businesses?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )}
                </div>

                {pagination?.totalPages > 1 && (
                  <Pagination
                    pagination={pagination}
                    data={businesses}
                  />
                )}
              </div>
            </div>
          </div>
          {/* {!closeAds && userType == 4 && (
            <DispalyAds setCloseAds={setCloseAds} />
          )} */}
          <AddBusiness
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            userType={3}
            closeModal={() => setIsOpen(false)}
          />
          <ConnectUser
            modalIsOpen={modalIsOpenUser}
            setIsOpen={setIsOpenUser}
            businessId={businessId}
            userId={userId}
            showName={showName}
            closeModal={() => setIsOpenUser(false)}
          />
          <AdminChangePassword
            modalIsOpen={changePasswordMember}
            setIsOpen={setChangePasswordMember}
            userId={userId}
            closeModal={() => setChangePasswordMember(false)}
          />
          <DeleteUserModal
            modalIsOpen={deleteUserShow}
            setIsOpen={setDeleteUserShow}
            userId={userId}
            userType={3}
            closeModal={() => setDeleteUserShow(false)}
          />
          <BussinessDetailsModal
            modalIsOpen={bussinessDetailsShow}
            setIsOpen={setBussinessDetailsShow}
            userInfo={userInfo}
            closeModal={() => setBussinessDetailsShow(false)}
          />
        </div>
      ) : (
        <OrganizationProfile
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setPageType={setPageType}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          getDetails={getDetails}
          history={history}
        />
      )}
    </>
  );
};

export default Buisnesses;
