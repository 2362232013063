// base url
export const URL = "https://clubunite.com/eleoapi";
// export const URL = "http://localhost:3005/eleoapi";

// apis endpoint
export const LOGIN = "user/login";
export const VALIDATE_EMAIL = "user/validateEmail";
export const GET_ORGANIZATION = "user/organization/all";
export const GET_BUSINESS = "user/business/all";
export const REGISTER = "user/signup";
export const GET_PROFILE = "user/profile";
export const GET_USERS = "user";
export const GET_ADS = "ad";
export const CONNECT = "user/connect";
export const MESSAGE = "user/message";
export const RESET_PASSWORD = "user/resetPassword";
export const VALIDATE_CODE = "user/validateCode";
export const CHANGE_PASSWORD = "user/changePassword";
export const UPLOAD_PROFILE = "user/profilePhoto";
export const UPLOAD_BANNER = "ad/bannerImage";
export const ADS_UPDATE = "ad/update";
export const ADMIN_CHANGE_PASSWORD = "user/admin/changePassword";
export const DELETE_USER = "user/admin/deleteUser";
export const DELETE_ADS = "ad/delete ";
export const CONNECTED_BUSINESS = "user/business/connected";
export const GET_DISPLAY_ADS = "ad/display";
