import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "../../forms/TextField";
import { inputValidator } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPasswordAction,
  resetPasswordAction,
  verificationCodeAction,
} from "../../store/actions/authAction";
import PublicLayout from "../../Layouts/PublicLayout";
import ChurchLoginImage from "../../assets/images/Church-Unite-Login.png";
import LoginImage from "../../assets/images/login.jpeg";
import { setUIKey } from "../../store/actions/uiAction";
import {
  CHURCH_UNITE_URL,
  CLUB_UNITE_URL,
  CLUB_URL_PREFIX,
  CHURCH_URL_PREFIX,
} from "../../utils/config";

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  code: yup.string().trim().required("Code is Required"),
  password: yup.string().trim().required("Password is Required"),
  confirmPassword: yup.string().trim().required("Confirm Password is Required"),
});

const ForgotPassword = ({ history }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeC, setPasswordTypeC] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState("reset");
  const [email, setEmail] = useState(null);
  const { error } = useSelector((state) => state.ui);
  const [message, setMessage] = useState("Enter Email");

  const dispatch = useDispatch();
  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendEmailHandler = async (e) => {
    e.preventDefault();

    const data = getValues();

    const result = await trigger(["email"]);
    if (result) {
      setEmail(data?.email);
      setIsLoading(true);
      const res = await dispatch(resetPasswordAction(data));
      setIsLoading(false);

      if (res.status === "success") {
        setFormType("code");
        setMessage("Enter verification code sent to your email " + data?.email);
        setValue("email", "");
      }
    }
  };

  const sendValidateCode = async (e) => {
    e.preventDefault();

    const data = getValues();

    const payload = {
      ...data,
      email,
    };

    const result = await trigger(["code"]);
    if (result) {
      setIsLoading(true);
      const res = await dispatch(verificationCodeAction(payload));
      setIsLoading(false);

      if (res.status === "success") {
        setFormType("password");
      }
    }
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const result = await trigger(["password", "confirmPassword"]);

    if (result) {
      const data = getValues();

      if (data.password !== data.confirmPassword) {
        dispatch(setUIKey("error", "Password does not match"));

        setTimeout(() => {
          dispatch(setUIKey("error", ""));
        }, 3000);
        return false;
      }

      const payload = {
        email,
        code: data.code,
        password: data.password,
      };

      setIsLoading(true);
      const res = await dispatch(forgotPasswordAction(payload));
      setIsLoading(false);

      if (res.status === "success") {
        setFormType("success");
      }
    }
  };

  return (
    <PublicLayout history={history}>
      <div className="authentication-wrapper authentication-cover medium">
        <div className="authentication-inner row g-0">
          <div className="col-md-6 ">
            <div className="authentication-img">
              <img
                src={
                  window.location.href?.includes("churchunite")
                    ? ChurchLoginImage
                    : LoginImage
                }
                className="img-fluid w-100"
              />
              {/* <div className="app-brand">
                <a
                  href="/"
                  className="app-brand-link w-100 h1 mb-0 text-white bold">
                  <em style={{ color: "#ccc" }}>Member Unite</em>
                </a>
              </div> */}
            </div>
          </div>
          <div className="d-flex col-md-6 align-items-center authentication-bg p-sm-5 p-4">
            <div className="w-px-400 mx-auto">
              {formType === "success" ? (
                <>
                  <h5
                    className="mb-4 text-center"
                    style={{ lineHeight: 1.5 }}>
                    Your Password is updated. Please Login With New Password.
                  </h5>

                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ paddingTop: 15 }}>
                    <button
                      role="submit"
                      className="btn w-100"
                      onClick={() =>
                        history.push(
                          window.location.href?.includes(CHURCH_UNITE_URL)
                            ? `${CHURCH_URL_PREFIX}/login`
                            : `${CLUB_URL_PREFIX}/login`,
                        )
                      }
                      disabled={isLoading}>
                      Back To Login
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="mb-2 semi">Reset Password</h2>
                  <p className="mb-4 ">Set a new password</p>
                </>
              )}

              {formType === "reset" ? (
                <form className="mb-3">
                  <TextField
                    errors={errors}
                    placeholder="Email"
                    register={register}
                    fieldName="email"
                  />

                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ paddingTop: 15 }}>
                    <a
                      role="button"
                      onClick={() =>
                        history.push(
                          window.location.href?.includes(CHURCH_UNITE_URL)
                            ? `${CHURCH_URL_PREFIX}/login`
                            : `${CLUB_URL_PREFIX}/login`,
                        )
                      }
                      className="col-6 semi">
                      Back To Login
                    </a>
                    <button
                      role="submit"
                      className="btn w-100"
                      onClick={sendEmailHandler}
                      disabled={isLoading}>
                      {isLoading ? "Processing" : "Continue"}
                    </button>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                      marginTop: 20,
                    }}>
                    {error ? error : ""}
                  </p>
                </form>
              ) : formType === "code" ? (
                <form className="mb-3">
                  <TextField
                    errors={errors}
                    placeholder="Verification Code"
                    register={register}
                    fieldName="code"
                  />

                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ paddingTop: 15 }}>
                    <a
                      role="button"
                      onClick={() =>
                        history.push(
                          window.location.href?.includes(CHURCH_UNITE_URL)
                            ? `${CHURCH_URL_PREFIX}/login`
                            : `${CLUB_URL_PREFIX}/login`,
                        )
                      }
                      className="col-6 semi">
                      Back To Login
                    </a>
                    <button
                      role="submit"
                      className="btn w-100"
                      onClick={sendValidateCode}
                      disabled={isLoading}>
                      {isLoading ? "Processing" : "Continue"}
                    </button>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                      marginTop: 20,
                    }}>
                    {error ? error : ""}
                  </p>
                </form>
              ) : (
                formType === "password" && (
                  <form className="mb-3">
                    <TextField
                      inputType={passwordType}
                      errors={errors}
                      divClass="style-line mb-3 form-password-toggle"
                      placeholder="Password"
                      register={register}
                      fieldName="password"
                      icon={<i className="far fa-eye-slash"></i>}
                      viewPassword={() =>
                        setPasswordType(
                          passwordType === "password" ? "text" : "password",
                        )
                      }
                    />

                    <TextField
                      inputType={passwordTypeC}
                      errors={errors}
                      divClass="style-line mb-3 form-password-toggle"
                      placeholder="Confirm Password"
                      register={register}
                      fieldName="confirmPassword"
                      icon={<i className="far fa-eye-slash"></i>}
                      viewPassword={() =>
                        setPasswordTypeC(
                          passwordTypeC === "password" ? "text" : "password",
                        )
                      }
                    />

                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingTop: 15 }}>
                      <a
                        role="button"
                        onClick={() =>
                          history.push(
                            window.location.href?.includes(CHURCH_UNITE_URL)
                              ? `${CHURCH_URL_PREFIX}/login`
                              : `${CLUB_URL_PREFIX}/login`,
                          )
                        }
                        className="col-6 semi">
                        Back To Login
                      </a>
                      <button
                        role="submit"
                        className="btn w-100"
                        onClick={resetPasswordHandler}
                        disabled={isLoading}>
                        {isLoading ? "Processing" : "Change Password"}
                      </button>
                    </div>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "red",
                        marginTop: 20,
                      }}>
                      {error ? error : ""}
                    </p>
                  </form>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default ForgotPassword;
