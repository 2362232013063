import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCropModal = ({ avatar, uploadImgage, imgType, org }) => {
  const [zoom, setZoom] = useState([0, 0, 0, 0]);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState();

  const cropperRef = useRef([]);

  const getCropData = () => {
    if (cropper) {
      let urlType = "image/jpeg";
      let dataUrl = cropper.getCroppedCanvas().toDataURL(urlType, 0.6);
      createFile(dataUrl);
    }
  };

  const createFile = async (dataURL) => {
    uploadImgage(dataURL);
  };

  return (
    <div style={{ paddingTop: 70 }}>
      <Cropper
        zoomTo={zoom}
        initialAspectRatio={org ? 4 / 1 : imgType === "web" ? 12.8 / 1 : 8 / 1}
        src={avatar}
        dragMode="move"
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        cropBoxResizable={false}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        ref={(el) => cropperRef.current}
        guides={false}
      />

      <button
        className="btn w-100 mt-3 wizard-next-btn"
        onClick={getCropData}>
        Crop
      </button>
    </div>
  );
};

export default ImageCropModal;
