import React from "react";
import { getLocalData } from "../../utils/functions";
import { useSelector } from "react-redux";
import logo_club from "../../assets/images/club-logo.png";
import logo_church from "../../assets/images/church-logo.png";
import { CHURCH_URL_PREFIX, CLUB_URL_PREFIX, CHURCH_UNITE_URL } from "../../utils/config";

const Sidebar = ({ pathName, history, showSidebar, setShowSidebar }) => {
  const userType = getLocalData("userType");

  const { userInfo } = useSelector((state) => state.dashboard);

  return (
    <aside
      id="layout-menu"
      className={`layout-menu menu-vertical menu bg-menu-theme ${
        showSidebar ? "show-sidebar" : ""
      } `}>
      <div
        className="py-lg-3 mb-3"
        style={{ backgroundColor: "", textAlign: "center" }}>
        {/* <span className="app-brand-link w-100 h1 text-white bold">
          <em>ELEO</em>
        </span> */}

        <img
          src={
            window.location.href?.includes(CHURCH_UNITE_URL) 
              ? logo_church
              : logo_club
          }
          style={{
            height: 100,
            marginLeft: -20,
            marginTop: 0,
          }}
        />
      </div>
      {userInfo?.bannerImage && userType == 2 && (
        <img
          src={userInfo?.bannerImage}
          style={{ marginTop: -60, padding: 20, marginBottom: 30 }}
        />
      )}

      <div id="menu-scroll">
        <div className="menu-block">
          <ul className="menu-inner">
            {userType != 4 && (
              <>
                <li
                  className={`menu-item ${
                    pathName === "/dashboard" ||
                    pathName === "/churchunite/dashboard" ||
                    pathName === "/clubunite/dashboard"
                      ? "active"
                      : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      window.location.href?.includes(CHURCH_UNITE_URL) 
                        ? history.push(`${CHURCH_URL_PREFIX}/dashboard`)
                        : history.push(`${CLUB_URL_PREFIX}/dashboard`);

                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons far fa-home-alt"></i>
                    <div>My Club Dashboard</div>
                  </a>
                </li>
                {userType == 3 || userType == 2 ? (
                  <li
                    className={`menu-item ${
                      pathName === "/profile" ||
                      pathName === "/churchunite/profile" ||
                      pathName === "/clubunite/profile"
                        ? "active"
                        : ""
                    }`}>
                    <a
                      role="button"
                      onClick={() => {
                        window.location.href?.includes(CHURCH_UNITE_URL) 
                          ? history.push(`${CHURCH_URL_PREFIX}/profile`)
                          : history.push(`${CLUB_URL_PREFIX}/profile`);
                        setShowSidebar(false);
                      }}
                      className="menu-link">
                      <i className="menu-icon tf-icons far fa-user-alt"></i>
                      <div>My Club Profile</div>
                    </a>
                  </li>
                ) : (
                  <li
                    className={`menu-item ${
                      pathName.includes("/organization") ? "active" : ""
                    }`}>
                    <a
                      role="button"
                      onClick={() => {
                        window.location.href?.includes(CHURCH_UNITE_URL) 
                        ? history.push(`${CHURCH_URL_PREFIX}/organization`)
                        : history.push(`${CLUB_URL_PREFIX}/organization`);
                        setShowSidebar(false);
                      }}
                      className="menu-link">
                      <i className="menu-icon tf-icons far fa-building"></i>
                      <div>My Club Organizations</div>
                    </a>
                  </li>
                )}

                <li
                  className={`menu-item ${
                    pathName.includes("/members") ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    onClick={() => {
                      window.location.href?.includes(CHURCH_UNITE_URL) 
                      ? history.push(`${CHURCH_URL_PREFIX}/members`)
                      : history.push(`${CLUB_URL_PREFIX}/members`);
                      setShowSidebar(false);
                    }}
                    className="menu-link">
                    <i className="menu-icon tf-icons far fa-users"></i>
                    <div>My Club Members</div>
                  </a>
                </li>
              </>
            )}

            {userType == 4 && (
              <li
                className={`menu-item ${
                  pathName === "/profile" ||
                  pathName === "/churchunite/profile" ||
                  pathName === "/clubunite/profile"
                    ? "active"
                    : ""
                }`}>
                <a
                  role="button"
                  onClick={() => {
                    window.location.href?.includes(CHURCH_UNITE_URL) 
                    ? history.push(`${CHURCH_URL_PREFIX}/profile`)
                    : history.push(`${CLUB_URL_PREFIX}/profile`);
                    setShowSidebar(false);
                  }}
                  className="menu-link">
                  <i className="menu-icon tf-icons far fa-user-alt"></i>
                  <div>My Club Profile</div>
                </a>
              </li>
            )}

            <li
              className={`menu-item ${
                pathName.includes("/businesses") ? "active" : ""
              }`}>
              <a
                role="button"
                onClick={() => {
                  window.location.href?.includes(CHURCH_UNITE_URL) 
                  ? history.push(`${CHURCH_URL_PREFIX}/businesses`)
                  : history.push(`${CLUB_URL_PREFIX}/businesses`);
                  setShowSidebar(false);
                }}
                className="menu-link">
                <i className="menu-icon tf-icons far fa-briefcase"></i>
                <div>My Club Businesses</div>
              </a>
            </li>

            {/* {userType == 3 && (
              <li
                className={`menu-item ${
                  pathName === "/connected-businesses" ? "active" : ""
                }`}>
                <a
                  role="button"
                  onClick={() => {
                    history.push("/connected-businesses");
                    setShowSidebar(false);
                  }}
                  className="menu-link">
                  <i className="menu-icon tf-icons far fa-briefcase"></i>
                  <div>Connected Businesses</div>
                </a>
              </li>
            )} */}
            {userType != 4 && (
              <li
                className={`menu-item ${pathName.includes("/ads") ? "active" : ""}`}>
                <a
                  role="button"
                  onClick={() => {
                    window.location.href?.includes(CHURCH_UNITE_URL) 
                    ? history.push(`${CHURCH_URL_PREFIX}/ads`)
                    : history.push(`${CLUB_URL_PREFIX}/ads`);
                    setShowSidebar(false);
                  }}
                  className="menu-link">
                  <i className="menu-icon tf-icons far fa-bullhorn"></i>
                  <div>Ads</div>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
