import React, { useEffect, useState } from "react";
import defaultPic from "../../assets/img/profile-default.png";
import { useDispatch, useSelector } from "react-redux";
import TextField from "../../forms/TextField";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  capitalizeFirstLetter,
  getLocalData,
  inputValidator,
  interestesArray,
  phoneFormat,
} from "../../utils/functions";
import {
  getOrganizationOptions,
  updateUserProfile,
} from "../../store/actions/authAction";
import { setUIKey } from "../../store/actions/uiAction";
import {
  getCurrentUser,
  getUsers,
  updateProfilePhoto,
} from "../../store/actions/dashboardAction";
import moment from "moment";
import AddOrganisations from "../../modals/add-organizations";
import { CHURCH_UNITE_URL } from "../../utils/config";
import DeleteUserModal from "../../modals/delete-user";

const userSchema = yup.object({
  name: yup.string().trim().required("Name is Required"),
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
  orgId: yup.string().trim().required("Organization is Required"),
  memberSince: yup.string().trim().required("Member Since is Required"),
});

const Profile = ({
  userInfo,
  setPageType,
  setUserInfo,
  editProfile,
  setEditProfile,
  home,
  getDetails,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [success, setSuccess] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [interests, setInterests] = useState([]);
  const [addOrg, setAddOrg] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [status, setStatus] = useState(null);

  const dispatch = useDispatch();

  const { organization } = useSelector((state) => state.dashboard);
  const { organizationOptions } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.ui);

  const {
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    dispatch(getOrganizationOptions());
  }, []);

  const setUserInterests = (val) => {
    if (!editProfile) return;
    if (interests.includes(val)) {
      setInterests(interests.filter((int) => int !== val));
    } else {
      setInterests((oldVal) => [...oldVal, val]);
    }
  };

  useEffect(() => {
    if (userInfo) {
      setValue("name", userInfo.name);
      setValue("email", userInfo.email);
      setValue("phone", userInfo.phone);
      setValue("orgId", userInfo.orgId);
      setValue(
        "memberSince",
        moment(userInfo?.memberSince).format("YYYY-MM-DD"),
      );
      setInterests(userInfo?.interests?.split(","));
    }
  }, [userInfo]);

  const saveProfileHandler = async (e) => {
    e.preventDefault();

    const data = getValues();

    const result = await trigger([
      "name",
      "email",
      "phone",
      "orgId",
      "memberSince",
    ]);

    if (result) {
      const payload = {
        ...data,
        id: userInfo?.id,
        category: "",
        memberSince: data?.memberSince,
        interests: "",
        facebook: "",
        instagram: "",
        twitter: "",
        type: 4,
        profilePic: profilePic,
        interests: interests.join(","),
        status: status !== null ? status : userInfo?.status,
      };

      if (!profilePic) delete payload.profilePic;

      dispatch(setUIKey("error", ""));
      setIsLoading(true);
      const res = await dispatch(updateUserProfile(payload));
      setIsLoading(false);
      if (res?.status === "success") {
        setSuccess(true);
        setProfilePic(null);
        if (home) {
          dispatch(getCurrentUser());
        } else {
          dispatch(getUsers(4));
          getDetails(userInfo?.id, "user-profile", true);
        }

        setEditProfile(false);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        dispatch(setUIKey("error", res?.error));
      }
    }
  };

  const getOrgName = (orgId) => {
    return organizationOptions?.find((org) => org?.id === orgId)?.name || "-";
  };

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLoadingImg(true);
      var data = new FormData();
      data.append("profilePhoto", e.target.files[0]);

      const res = await dispatch(updateProfilePhoto(data));

      if (res?.profilePic) {
        setProfilePic(res?.profilePic);
      }

      setIsLoadingImg(false);
    }
  };

  const closeOrgModal = () => {
    setAddOrg(false);
    window.location.reload();
  };

  return (
    <div className="content-wrapper container-home">
      <div className="container-xxl flex-grow-1 container-p-b">
        <div className="card mb-3 mb-md-4">
          {setPageType && (
            <div
              className="col-md-4 text-start"
              style={{
                paddingTop: 20,
                paddingLeft: 40,
              }}>
              <a
                role="button"
                onClick={() => {
                  setPageType("table");
                  setUserInfo(null);
                  setEditProfile(false);
                }}>
                <i className="far fa-arrow-left me-2"></i> Back To Listing
              </a>
            </div>
          )}
          <div className="card-body">
            <div
              className={`row ${
                editProfile
                  ? "justify-content-start"
                  : "justify-content-between"
              }  g-4 mb-4 mb-md-5`}>
              {!editProfile && (
                <div
                  className="col-md-12"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}>
                  {getLocalData("userType") == 4 && (
                    <a
                      role="button"
                      onClick={() => setAddOrg(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        fill={
                          window.location.href?.includes(CHURCH_UNITE_URL)
                            ? "#134169"
                            : "#006747"
                        }
                        viewBox="0 0 512 512">
                        <path d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z" />
                      </svg>{" "}
                      {/* Add/Switch Organization */}
                      Organization
                    </a>
                  )}

                  {getLocalData("userType") != 3 && (
                    <a
                      role="button"
                      style={{ marginLeft: 20 }}
                      onClick={() => setEditProfile(true)}>
                      <i className="far fa-pen"></i> Edit Profile
                    </a>
                  )}
                  {/* {getLocalData("userType") != 3 && (
                    <a
                      role="button"
                      className="delete-button"
                      style={{ marginLeft: 20 }}
                      onClick={() => setDeleteUserShow(true)}>
                      <i className="far fa-trash"></i> Delete Account
                    </a>
                  )} */}
                </div>
              )}

              <div className="col-md-3">
                <img
                  src={
                    profilePic
                      ? `https://clubunite.com/eleo-uploads/uploads/profilephotos/${profilePic}`
                      : userInfo?.profilePic
                      ? userInfo?.profilePic
                      : defaultPic
                  }
                  className="img-fluid br-20 user-profile-image"
                />
                {editProfile && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: 15,
                      marginLeft: 45,
                    }}>
                    {isLoadingImg ? (
                      <label
                        for="upload"
                        class="file-upload mb-0"
                        tabindex="0">
                        Uploading
                      </label>
                    ) : (
                      <label
                        for="upload"
                        style={{
                          cursor: "pointer",
                          color: window.location.href?.includes(
                            CHURCH_UNITE_URL,
                          )
                            ? "#134169"
                            : "#006747",
                        }}
                        class="file-upload mb-0"
                        tabindex="0">
                        Change
                        <input
                          type="file"
                          id="upload"
                          class="account-file-input"
                          accept="image/png, image/jpeg, image/jpg"
                          hidden="true"
                          onChange={uploadImage}
                        />
                      </label>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-9">
                <div className="row g-3 g-md-4 medium">
                  <div className="col-md-6">
                    <label className="text-muted small mb-2">Name</label>
                    {editProfile ? (
                      <TextField
                        errors={errors}
                        placeholder="Name"
                        register={register}
                        fieldName="name"
                        inputClass="form-control edit-form-control"
                      />
                    ) : (
                      <div className="text-40">
                        {userInfo?.name ? userInfo?.name : "-"}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="text-muted small mb-2">
                      Organization
                    </label>
                    {editProfile ? (
                      <div className="style-line">
                        <select
                          disabled
                          className="form-select form-control form-control-sm"
                          onChange={(e) => setValue("orgId", +e.target.value)}>
                          <option
                            value=""
                            disabled
                            selected>
                            Organization
                          </option>
                          {organizationOptions?.map((val) => (
                            <option
                              value={val?.id}
                              key={val?.id}
                              selected={val?.id === userInfo?.orgId}>
                              {val?.name}
                            </option>
                          ))}
                        </select>
                        <span className="line"></span>
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "2px",
                          }}>
                          {errors?.["orgId"]?.message}
                        </p>
                      </div>
                    ) : (
                      <div className="text-40">
                        {getOrgName(userInfo?.orgId)}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="text-muted small mb-2">Email</label>
                    {editProfile ? (
                      <TextField
                        disabled={true}
                        errors={errors}
                        placeholder="Email"
                        register={register}
                        fieldName="email"
                      />
                    ) : (
                      <div className="text-40">
                        {userInfo?.email ? userInfo?.email : "-"}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="text-muted small mb-2">Phone</label>
                    {editProfile ? (
                      <TextField
                        errors={errors}
                        placeholder="Phone"
                        register={register}
                        fieldName="phone"
                      />
                    ) : (
                      <div className="text-40">
                        {userInfo?.phone ? phoneFormat(userInfo?.phone) : "-"}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="text-muted small mb-2">
                      Member Since
                    </label>
                    {editProfile ? (
                      <TextField
                        errors={errors}
                        placeholder="Member Since"
                        register={register}
                        inputType="date"
                        fieldName="memberSince"
                      />
                    ) : (
                      <div className="text-40">
                        {userInfo?.memberSince
                          ? moment(userInfo?.memberSince).format("MM/DD/YYYY")
                          : "-"}
                      </div>
                    )}
                  </div>
                </div>

                {(getLocalData("userType") == "1" ||
                  getLocalData("userType") == "2") && (
                  <div className="col-md-12 mt-4">
                    <label className="text-muted small mb-2">Status</label>
                    {editProfile ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: 40,
                        }}>
                        <ul
                          className="status-div"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}>
                          <li>
                            <input
                              type="radio"
                              id="pending"
                              name="selector"
                              onClick={() => setStatus("pending")}
                              checked={
                                status
                                  ? status === "pending"
                                  : userInfo?.status === "pending"
                              }
                            />
                            <label
                              for="pending"
                              className="pending-label">
                              Pending
                            </label>
                            <div className="check-pending"></div>
                          </li>

                          <li>
                            <input
                              type="radio"
                              id="f-option"
                              name="selector"
                              onClick={() => setStatus("approved")}
                              checked={
                                status
                                  ? status === "approved"
                                  : userInfo?.status === "approved"
                              }
                            />
                            <label for="f-option">Approved</label>
                            <div className="check"></div>
                          </li>

                          <li>
                            <input
                              type="radio"
                              id="s-option"
                              name="selector"
                              checked={
                                status
                                  ? status === "rejected"
                                  : userInfo?.status === "rejected"
                              }
                              onClick={() => setStatus("rejected")}
                            />
                            <label
                              for="s-option"
                              className="reject-label">
                              Rejected
                            </label>
                            <div className="reject"></div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div className="text-40">
                        {userInfo?.status
                          ? capitalizeFirstLetter(userInfo?.status)
                          : "-"}
                      </div>
                    )}
                  </div>
                )}

                <div className="form-block col-md-12 mt-4">
                  <label className="text-muted small mb-2">Interests</label>

                  {!editProfile && (
                    <div className="text-40">
                      {userInfo?.interests
                        ? userInfo?.interests?.startsWith(",")
                          ? userInfo?.interests
                              ?.replace(",", "")
                              ?.replaceAll(",", ", ")
                          : userInfo?.interests?.replaceAll(",", ", ")
                        : "-"}
                    </div>
                  )}

                  {editProfile && (
                    <div className="pp-type d-flex align-items-center flex-wrap mb-4">
                      {interestesArray.map((val, i) => (
                        <div
                          className="form-check"
                          for={val}
                          key={i}>
                          <input
                            className="form-check-input "
                            name="interests"
                            type="checkbox"
                            checked={interests.includes(val)}
                            id={val}
                          />
                          <label
                            className={`form-check-label`}
                            onClick={() => setUserInterests(val)}>
                            {val}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {editProfile && (
                  <div
                    className="row"
                    style={{ marginTop: -40 }}>
                    <div className="col-md-6">
                      <button
                        onClick={saveProfileHandler}
                        disabled={isLoading}
                        className="btn w-100 mt-5 wizard-next-btn">
                        {isLoading ? "Updating" : "Update"}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        onClick={() => setEditProfile(false)}
                        disabled={isLoading}
                        className="btn w-100 mt-5 wizard-next-btn grey-btn">
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {editProfile && success ? (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "green",
                }}>
                Profile Update Successfully!
              </p>
            ) : (
              editProfile && (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                  }}>
                  {error && !isLoading ? error : ""}
                </p>
              )
            )}
          </div>
        </div>
      </div>
      <DeleteUserModal
        modalIsOpen={deleteUserShow}
        setIsOpen={setDeleteUserShow}
        accountProfile={true}
        closeModal={() => setDeleteUserShow(false)}
      />
      {addOrg && (
        <AddOrganisations
          modalIsOpen={addOrg}
          setIsOpen={setAddOrg}
          closeModal={closeOrgModal}
          organizationOptions={organizationOptions}
          getOrgName={getOrgName}
          history={history}
        />
      )}
    </div>
  );
};

export default Profile;
