import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrivateLayout from "../../Layouts/PrivateLayout";
import Home from "./home";
import Organization from "./organization";
import Members from "./members";
import Sidebar from "./sidebar";
import Header from "./header";
import Buisnesses from "./buisnesses";
import Profile from "./member-profile";
import OrganizationProfile from "./organization-profile";
import { getLocalData } from "../../utils/functions";
import Ads from "./ads";
import { getCurrentUser } from "../../store/actions/dashboardAction";
import { setUserToken } from "../../store/actions/authAction";
import { CHURCH_UNITE_URL, CLUB_UNITE_URL } from "../../utils/config";

const Dashboard = ({ history }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const pathName = window.location.pathname;
  const userType = getLocalData("userType");

  const { userInfo } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname?.includes("churchunite")) {
      sessionStorage.setItem("type", "churchunite");
    } else if (window.location.pathname?.includes("clubunite")) {
      sessionStorage.setItem("type", "clubunite");
    }
  }, [window.location.href]);

  useEffect(() => {
    if (window.location.search?.includes("?token=")) {
      localStorage.setItem(
        "token",
        window.location.search?.replace("?token=", ""),
      );
      window.location.replace(
        window.location.href?.replace(window.location.search, ""),
      );
    }
  }, [window.location.href]);

  useEffect(() => {
    if (userInfo?.category) {
      if (
        userInfo?.category === "Church" &&
        window.location.href?.includes(CLUB_UNITE_URL)
      ){
        window.location.replace(
          `${CHURCH_UNITE_URL}/profile?token=${localStorage.token}`,
        );
      } else if(
        userInfo?.category === "Golf Club" &&
        window.location.href?.includes(CHURCH_UNITE_URL)
      ){
        window.location.replace(
          `${CLUB_UNITE_URL}/profile?token=${localStorage.token}`,
        );
      }  
    }
  }, [userInfo]);

  return (
    <PrivateLayout history={history}>
      <div className="layout-wrapper layout-content-navbar page-container">
        <div className="layout-container">
          <Sidebar
            pathName={pathName}
            history={history}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />

          <div className="layout-page">
            <Header
              history={history}
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />

            <div className="content-wrapper">
              {pathName === "/dashboard" && <Home history={history} />}
              {pathName === "/churchunite/dashboard" && (
                <Home history={history} />
              )}
              {pathName === "/clubunite/dashboard" && (
                <Home history={history} />
              )}
              {pathName.includes("/organization") && (
                <Organization history={history} />
              )}
              {pathName.includes("/members") && <Members history={history} />}
              {pathName.includes("/businesses") && <Buisnesses />}
              {pathName.includes("/connected-businesses") && (
                <Buisnesses
                  connected={true}
                  history={history}
                />
              )}
              {pathName.includes("/ads") && <Ads history={history} />}
              {(pathName === "/profile" ||
                pathName === "/clubunite/profile" ||
                pathName === "/churchunite/profile") &&
                userType == 4 && (
                  <Profile
                    userInfo={userInfo}
                    editProfile={editProfile}
                    setEditProfile={setEditProfile}
                    home={true}
                    history={history}
                  />
                )}
              {(pathName === "/profile" ||
                pathName === "/clubunite/profile" ||
                pathName === "/churchunite/profile") &&
                (userType == 3 || userType == 2) && (
                  <OrganizationProfile
                    userInfo={userInfo}
                    editProfile={editProfile}
                    setEditProfile={setEditProfile}
                    home={true}
                    history={history}
                  />
                )}
            </div>
          </div>
        </div>
        {showSidebar && (
          <div
            className="layout-overlay-sidebar"
            onClick={() => setShowSidebar(false)}></div>
        )}
      </div>
    </PrivateLayout>
  );
};

export default Dashboard;
