import {
  ADMIN_CHANGE_PASSWORD,
  CHANGE_PASSWORD,
  CONNECT,
  DELETE_ADS,
  DELETE_USER,
  GET_BUSINESS,
  GET_ORGANIZATION,
  GET_PROFILE,
  LOGIN,
  MESSAGE,
  REGISTER,
  RESET_PASSWORD,
  URL,
  VALIDATE_CODE,
  VALIDATE_EMAIL,
} from "../../apis/routes";
import ApiService from "../../services/ApiServices";
import { CHURCH_UNITE_URL, CLUB_UNITE_URL } from "../../utils/config";
import { getLocalData, setLocalData } from "../../utils/functions";
import { SET_USER_DATA } from "../constants/authConstants";
import { setUIKey } from "./uiAction";

export const loginUser = (data) => async (dispatch) => {
  try {
    const res = await ApiService.post(`${URL}/${LOGIN}`, data);

    if (res?.data?.status === "success") {
      setLocalData("token", res?.data?.token || "");
      setLocalData("userName", res?.data?.name || "");
      setLocalData("userType", res?.data?.type || "");
      dispatch(setUserToken());
      dispatch(setUIKey("error", ""));
    } else {
      dispatch(setUIKey("error", res?.data?.error));

      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);
    }
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loginUserByOrg = (data) => async (dispatch) => {
  try {
    const res = await ApiService.post(`${URL}/user/loginByOrg`, data);

    if (res?.data?.status === "success") {
      if (
        res.data.theme === "blue" &&
        window.location.href?.includes(CHURCH_UNITE_URL)
      ) {
        setLocalData("token", res?.data?.token || "");
        setLocalData("userName", res?.data?.name || "");
        setLocalData("userType", res?.data?.type || "");
        dispatch(setUserToken());
        dispatch(setUIKey("error", ""));
      }
      if (
        res.data.theme === "green" &&
        window.location.href?.includes(CLUB_UNITE_URL)
      ) {
        setLocalData("token", res?.data?.token || "");
        setLocalData("userName", res?.data?.name || "");
        setLocalData("userType", res?.data?.type || "");
        dispatch(setUserToken());
        dispatch(setUIKey("error", ""));
      }
    } else {
      dispatch(setUIKey("error", res?.data?.error || "Error"));

      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);
    }
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const resetPasswordAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${RESET_PASSWORD}`, data);

    if (res?.data?.status !== "success") {
      dispatch(setUIKey("error", res?.data?.error || "Error"));

      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);
    }
    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const verificationCodeAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${VALIDATE_CODE}`, data);

    if (res?.data?.status !== "success") {
      dispatch(setUIKey("error", res?.data?.error || "Error"));

      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);
    }

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const forgotPasswordAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${CHANGE_PASSWORD}`, data);

    if (res?.data?.status !== "success") {
      dispatch(setUIKey("error", res?.data?.error || "Error"));

      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);
    }

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const registerUser = (data, newMember) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${REGISTER}`, data);

    if (res?.data?.status === "success" && !newMember) {
      setLocalData("token", res?.data?.token || "");
      setLocalData("userName", res?.data?.name || "");
      setLocalData("userType", res?.data?.type || "");
      dispatch(setUserToken());
    } else {
      dispatch(setUIKey("error", res?.data?.error || "Error"));

      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);
    }

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const checkEmail = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${VALIDATE_EMAIL}`, data);
    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateUserProfile = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${GET_PROFILE}`, data);
    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserProfile = (data) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_PROFILE}`);
    if (res?.data) {
      dispatch(setUserData({ loginUser: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserProfileById = (id) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_PROFILE}/${id}`);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const setUserData = (data) => ({
  type: SET_USER_DATA,
  payload: data,
});

export const setUserToken = () => async (dispatch) => {
  let token = getLocalData("token") || "";

  dispatch({
    type: SET_USER_DATA,
    payload: {
      token,
    },
  });
};

export const getOrganizationOptions = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_ORGANIZATION}`);

    if (res?.data) {
      dispatch(setUserData({ organizationOptions: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getBusinessOptions = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_BUSINESS}`);

    if (res?.data) {
      dispatch(setUserData({ businessesOptions: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const connectUserAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${CONNECT}`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const sendEmailCode = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/user/verifyEmail`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const verifyEmailCode = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/user/validateCode`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const sendMessageAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${MESSAGE}`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const forgotAdminPassword = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${ADMIN_CHANGE_PASSWORD}`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteUserAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${DELETE_USER}`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteAds = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${DELETE_ADS}`, data);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
