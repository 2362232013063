import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "../../forms/TextField";
import { inputValidator } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationOptions,
  loginUser,
  loginUserByOrg,
} from "../../store/actions/authAction";
import PublicLayout from "../../Layouts/PublicLayout";
import LoginImage from "../../assets/images/login.jpeg";
import ChurchLoginImage from "../../assets/images/Church-Unite-Login.png";
import logo_golf from "../../assets/images/club-logo.png";
import logo_church from "../../assets/images/church-logo.png";
import { setUIKey } from "../../store/actions/uiAction";
import {
  CHURCH_UNITE_URL,
  CLUB_UNITE_URL,
  CHURCH_URL_PREFIX,
  CLUB_URL_PREFIX,
} from "../../utils/config";

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .required("Email is Required")
    .matches(inputValidator.emailRegx, "Invalid Email"),
  password: yup.string().trim().required("Password is Required"),
});

const Login = ({ history }) => {
  const [passwordType, setPasswordType] = useState("password");
  const { error } = useSelector((state) => state.ui);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectCheckbox, setSelectCheckbox] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationOptions());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loginHandler = async (data) => {
    const payload = { ...data };

    if (!selectCheckbox) {
      dispatch(setUIKey("error", "Please check the consent box"));
      setTimeout(() => {
        dispatch(setUIKey("error", ""));
      }, 3000);

      return false;
    }

    dispatch(setUIKey("error", ""));

    setIsLoading(true);
    const res = await dispatch(loginUser(payload));

    setIsLoading(false);

    if (res?.length > 1) {
      setOrgs(res);
    }

    if (res.status === "success" && !res?.isApproved) {
      return setIsApproved(false);
    } else if (res.status === "success") {
      dispatch(setUIKey("error", ""));
    }

    if (res.status === "success" && res?.isApproved) {
      if (res.status === "success" && res?.isApproved) {
        window.location.replace(
          `${res.theme === "blue" ? CHURCH_UNITE_URL : CLUB_UNITE_URL}/${
            res.type === 4 ? "profile" : "dashboard"
          }?token=${res.token}`,
        );
      }
    }
  };

  const loginHandlerByOrg = async (selectedOrgId) => {
    const payload = {
      orgId: selectedOrgId,
      userId: orgs?.find((val) => val?.orgId === selectedOrgId)?.userId,
    };

    if (orgs?.length > 0 && !selectedOrgId) {
      setSuccess("");
      return dispatch(
        setUIKey("error", "Please select the organization to continue"),
      );
    }
    dispatch(setUIKey("error", ""));

    setIsLoading(true);
    const res = await dispatch(loginUserByOrg(payload));

    setIsLoading(false);

    if (res.status === "success" && !res?.isApproved) {
      return setIsApproved(false);
    } else if (res.status === "success") {
      setSuccess("Please select the organization you wish to login with");
      dispatch(setUIKey("error", ""));
    }

    if (res.status === "success" && res?.isApproved) {
      window.location.replace(
        `${res.theme === "blue" ? CHURCH_UNITE_URL : CLUB_UNITE_URL}/${
          res.type === 4 ? "profile" : "dashboard"
        }?token=${res.token}`,
      );
    }
  };

  return (
    <PublicLayout history={history}>
      <div className="authentication-wrapper authentication-cover medium">
        <div className="authentication-inner row g-0">
          <div className="col-md-6 ">
            <div className="authentication-img">
              <img
                src={
                  window.location.href?.includes("churchunite")
                    ? ChurchLoginImage
                    : LoginImage
                }
                className="img-fluid w-100"
              />
              {/* <div className="app-brand">
                <a
                  href="/"
                  className="app-brand-link w-100 h1 mb-0 bold">
                  <em style={{ color: "#ccc" }}>ELEO</em>
                </a>
              </div> */}
            </div>
          </div>
          <div className="d-flex col-md-6 align-items-center authentication-bg p-sm-5 p-4">
            {isApproved ? (
              <div className="w-px-400 mx-auto">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={
                      window.location.href?.includes("churchunite")
                        ? logo_church
                        : logo_golf
                    }
                    className="img-fluid"
                    style={{
                      height: 200,
                      marginBottom: 50,
                    }}
                  />
                </div>
                <h2 className="mb-2 semi">
                  {orgs?.length ? "Select Organization" : "Login"}{" "}
                </h2>
                <p className="mb-4 ">
                  New user?{" "}
                  <a
                    role="button"
                    onClick={() =>
                      history.push(
                        window.location.href?.includes(CHURCH_UNITE_URL)
                          ? `${CHURCH_URL_PREFIX}/register`
                          : `${CLUB_URL_PREFIX}/register`,
                      )
                    }>
                    Create an account{" "}
                  </a>
                </p>

                <form
                  onSubmit={handleSubmit(loginHandler)}
                  className="mb-3">
                  {orgs?.length === 0 && (
                    <>
                      <TextField
                        errors={errors}
                        placeholder="Email"
                        register={register}
                        fieldName="email"
                      />

                      <TextField
                        inputType={passwordType}
                        errors={errors}
                        divClass="style-line mb-3 form-password-toggle"
                        placeholder="Password"
                        register={register}
                        fieldName="password"
                        icon={<i className="far fa-eye-slash"></i>}
                        viewPassword={() =>
                          setPasswordType(
                            passwordType === "password" ? "text" : "password",
                          )
                        }
                      />
                    </>
                  )}

                  {orgs?.length > 0 && (
                    // <div className="style-line">
                    //   <select
                    //     className="form-select form-control form-control-sm"
                    //     onChange={(e) => setOrgId(+e.target.value)}>
                    //     <option
                    //       value=""
                    //       disabled
                    //       selected>
                    //       Organization
                    //     </option>
                    //     {orgs?.map((val) => (
                    //       <option
                    //         value={val?.orgId}
                    //         key={val?.orgId}>
                    //         {val?.orgName}
                    //       </option>
                    //     ))}
                    //   </select>
                    //   <span className="line"></span>
                    // </div>
                    <div className="form-wizard">
                      {orgs?.map((val) => (
                        <div className="row">
                          <div className="col-md-8">
                            <p
                              style={{
                                fontWeight: 600,
                                color: "#333",
                                fontSize: 16,
                                marginBottom: 10,
                              }}
                              className="form-check-label"
                              htmlFor={`check-${val?.id}`}>
                              {val?.orgName}
                            </p>
                          </div>

                          <div className="col-md-4 row">
                            <a
                              role="button"
                              onClick={() => loginHandlerByOrg(val?.orgId)}
                              className="col-md-12"
                              style={{
                                fontWeight: 600,
                                color: "#333",
                                padding: 0,
                              }}>
                              Login
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ paddingTop: 15 }}>
                    {orgs?.length === 0 && (
                      <a
                        role="button"
                        onClick={() =>
                          history.push(
                            window.location.href?.includes(CHURCH_UNITE_URL)
                              ? `${CHURCH_URL_PREFIX}/forgot-password`
                              : `${CLUB_URL_PREFIX}/forgot-password`,
                          )
                        }
                        className="col-6 semi">
                        Forgot Password?
                      </a>
                    )}

                    {orgs?.length > 0 && (
                      <a
                        role="button"
                        onClick={() => {
                          setOrgs([]);
                          reset();
                        }}
                        className="col-6 semi">
                        Back To Login
                      </a>
                    )}
                    {orgs?.length === 0 && (
                      <button
                        role="submit"
                        className="btn w-100"
                        disabled={isLoading}>
                        {isLoading ? "Logging in..." : "Log in"}
                      </button>
                    )}
                  </div>
                  <div className="mt-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        style={{ border: "1px solid #ccc" }}
                        id="consent"
                        onChange={(e) => setSelectCheckbox(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        for="consent">
                        I consent to my information being shared with other
                        members{" "}
                        <a
                          role="button"
                          target="_blank"
                          href="https://clubunite.com/privacy-policy/">
                          <u> View Privacy Policy</u>
                        </a>
                      </label>
                    </div>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                      marginTop: 20,
                    }}>
                    {error && !success ? error : ""}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "green",
                    }}>
                    {success ? success : ""}
                  </p>
                </form>
              </div>
            ) : (
              <div className="w-px-400 mx-auto">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={
                      window.location.href?.includes("churchunite")
                        ? logo_church
                        : logo_golf
                    }
                    className="img-fluid"
                    style={{ height: 200, marginBottom: 50 }}
                  />
                </div>
                {/* <h3 className="mb-2 semi">Account is not approved</h3> */}
                <p className="mb-4 mt-4">
                  Your account has been submitted for verification. We will
                  notify you as soon as the account is approved. Thank you
                </p>

                <a
                  role="button"
                  onClick={() => {
                    setIsApproved(true);
                    reset();
                  }}
                  className="col-6 semi">
                  Back To Login
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Login;
