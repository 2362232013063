import React, { useEffect, useState } from "react";
import defaultPic from "../../assets/img/profile-default.png";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../store/actions/dashboardAction";
import moment from "moment";
import { getLocalData, showDataPerPage } from "../../utils/functions";
import Pagination from "./Pagination";
import { useComponentVisible, usePagination } from "../../apis/hooks";
import AddMember from "../../modals/add-member";
import Profile from "./member-profile";
import {
  getOrganizationOptions,
  getUserProfileById,
} from "../../store/actions/authAction";
import ConnectUser from "../../modals/connect-user";
import AdminChangePassword from "../../modals/admin-change-password";
import DeleteUserModal from "../../modals/delete-user";
import BussinessDetailsModal from "../../modals/bussiness-details-modal";

const Members = ({ history }) => {
  const [showData, setShowData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenUser, setIsOpenUser] = useState(false);
  const [changePasswordMember, setChangePasswordMember] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showName, setShowName] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [pageType, setPageType] = useState("table");
  const [userInfo, setUserInfo] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [bussinessDetailsShow, setBussinessDetailsShow] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");

  const dispatch = useDispatch();

  const { members } = useSelector((state) => state.dashboard);
  const { isLoading } = useSelector((state) => state.ui);
  const { organizationOptions } = useSelector((state) => state.auth);

  const userType = getLocalData("userType");

  useEffect(() => {
    dispatch(getUsers(4));

    dispatch(getOrganizationOptions());
  }, []);

  const pagination = usePagination(
    members
      ?.filter((member) =>
        member?.name?.toLowerCase()?.includes(searchText?.toLocaleLowerCase()),
      )
      ?.filter((member) =>
        selectedOrg ? member?.orgId === selectedOrg : member,
      )?.length,
    showDataPerPage,
  );

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  useEffect(() => {
    if (members?.length && pagination.current > 0) {
      setShowData(
        members

          ?.filter((member) =>
            member?.name
              ?.toLowerCase()
              ?.includes(searchText?.toLocaleLowerCase()),
          )
          ?.filter((member) =>
            selectedOrg ? member?.orgId === selectedOrg : member,
          )
          ?.slice(
            (pagination.current - 1) * showDataPerPage,
            pagination.current * showDataPerPage,
          ),
      );
    }
  }, [members, searchText, pagination.current, selectedOrg]);

  const getOrgName = (orgId) => {
    return organizationOptions?.find((org) => org?.id === orgId)?.name || "-";
  };

  const getDetails = async (id, isEdit) => {
    setSelectedData(null);
    if (isEdit) {
      setEditProfile(true);
      setPageType("profile");
    } else {
      setEditProfile(false);
      setBussinessDetailsShow(true);
    }
    const res = await dispatch(getUserProfileById(id));
    setUserInfo(res);
  };

  return (
    <>
      {pageType === "table" ? (
        <div
          className="content-wrapper container-home"
          style={{ paddingTop: 20 }}>
          <div className="container-xxl flex-grow-1 container-p-b">
            <div className="card mb-3 mb-md-4">
              <div className="card-header flex-row d-md-flex align-items-center justify-content-between row">
                <div className="flex-row d-flex align-items-center col-md-8 row">
                  <h5 className="mb-0 medium text-nowrap col-md-3">
                    Members{" "}
                    {(!isLoading || members?.length) &&
                      `(${members?.length || 0})`}
                  </h5>
                  <div className="col-md-5 search-box">
                    <div className="input-group ms-2 ms-md-3 org-search">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text border-0 h-100"
                          id="basic-addon1">
                          <i className="far fa-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control border-0 ps-0 "
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                  </div>

                  {userType == 1 && (
                    <div className="style-line col-md-4 filter-box">
                      <select
                        style={{
                          border: "1px solid #d9dee3",
                          borderRadius: "0.375rem",
                          marginLeft: 10,
                        }}
                        className="form-select form-control form-control-sm"
                        onChange={(e) => {
                          setSelectedOrg(+e.target.value);
                          pagination.goToPage(1);
                        }}>
                        <option
                          value=""
                          selected>
                          All Organizations
                        </option>
                        {organizationOptions?.map((val) => (
                          <option
                            value={val?.id}
                            key={val?.id}
                            selected={val?.id === userInfo?.orgId}>
                            {val?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                {userType != 3 && (
                  <div className="col-md-2 add-button">
                    <a
                      role="button"
                      onClick={() => setIsOpen(true)}
                      className="btn">
                      + New{" "}
                    </a>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="table-responsive-xs text-nowrap mb-4">
                  <table className="table table-borderless medium data-table-block">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Organization</th>
                        <th>Member Since</th>
                        <th>{userType == 3 ? "Connected" : "Last Login"} </th>
                        {(userType == 1 || userType == 2) && <th>Status</th>}
                        {(userType == 1 || userType == 2) && <th>Actions</th>}
                      </tr>
                    </thead>

                    <tbody className="table-border-bottom-0 text-1e">
                      {showData?.map((member) => (
                        <tr key={member?.id}>
                          <td>
                            <img
                              src={
                                member?.profilePic
                                  ? member?.profilePic
                                  : defaultPic
                              }
                              alt="Avatar"
                              className="w-px-30 h-px-30 rounded-circle me-2"
                            />
                            {member?.name}
                          </td>
                          <td>{getOrgName(member?.orgId)}</td>
                          <td>
                            {member?.memberSince
                              ? moment(member?.memberSince).format("MM/DD/YYYY")
                              : moment(member?.created_at).format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {moment(member?.lastLogin).format("MM/DD/YYYY")}
                          </td>
                          {(userType == 1 || userType == 2) && (
                            <span
                              class={`badge bg-label-${
                                member?.status === "approved"
                                  ? "success"
                                  : member?.status === "rejected"
                                  ? "danger"
                                  : "warning"
                              } `}>
                              {member?.status === "approved"
                                ? "Approved"
                                : member?.status === "rejected"
                                ? "Rejected"
                                : "Pending"}{" "}
                            </span>
                          )}
                          {userType == 3 ? (
                            <td>
                              <a
                                role="button"
                                target="_default"
                                href={
                                  "mailto:" +
                                  member?.email +
                                  "?subject=Member Unite Connect"
                                }
                                // onClick={() => {
                                //   setUserId(member?.id);
                                //   setShowName(member?.name);
                                //   setIsOpenUser(true);
                                // }}
                                className="btn table-btn">
                                Message
                              </a>
                              <br />
                              <a
                                role="button"
                                style={{
                                  marginTop: "10px",
                                  paddingLeft: "16px",
                                  fontSize: 10,
                                  textAlign: "center",
                                }}
                                onClick={() =>
                                  getDetails(member?.id, false, true)
                                }>
                                View Profile
                              </a>
                            </td>
                          ) : (
                            <td>
                              <div className="dropdown">
                                <button
                                  onClick={() => {
                                    setSelectedData(member.id);
                                    setIsComponentVisible(true);
                                  }}
                                  type="button"
                                  className={`btn btn-outline dropdown-toggle hide-arrow ${
                                    selectedData === member?.id ? "show" : ""
                                  }`}
                                  data-bs-toggle="dropdown">
                                  <i className="fas fa-ellipsis-h"></i>
                                </button>
                                <div
                                  ref={ref}
                                  className={`dropdown-menu dropdown-menu-top ${
                                    selectedData === member?.id &&
                                    isComponentVisible
                                      ? "show"
                                      : ""
                                  } `}>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => getDetails(member?.id)}>
                                    <i className="far fa-eye me-1"></i> View
                                    Profile
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() =>
                                      getDetails(member?.id, true)
                                    }>
                                    <i className="far fa-edit me-1"></i> Edit
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      setDeleteUserShow(true);
                                      setSelectedData(null);
                                      setUserId(member);
                                    }}>
                                    <i className="far fa-trash-alt me-1"></i>{" "}
                                    Delete
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    onClick={() => {
                                      setChangePasswordMember(true);
                                      setSelectedData(null);
                                      setUserId(member?.id);
                                    }}>
                                    <i className="far fa-lock-alt me-1"></i>{" "}
                                    Reset Password
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    role="button"
                                    target="_default"
                                    href={
                                      "mailto:" +
                                      member?.email +
                                      "?subject=Member Unite Connect"
                                    }
                                    // onClick={() => {
                                    //   setIsOpenUser(true);
                                    //   setUserId(member?.id);
                                    //   setShowName(member?.name);
                                    //   setSelectedData(null);
                                    // }}
                                  >
                                    <i className="far fa-comment me-1"></i>{" "}
                                    Message
                                  </a>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && !members?.length && (
                    <div className="loading-text">
                      <p>Loading...</p>
                    </div>
                  )}
                  {!isLoading && !members?.length && (
                    <div className="loading-text">
                      <p>No Data</p>
                    </div>
                  )}
                </div>

                {pagination?.totalPages > 1 && (
                  <Pagination
                    pagination={pagination}
                    data={members}
                  />
                )}
              </div>
            </div>
          </div>
          <BussinessDetailsModal
            modalIsOpen={bussinessDetailsShow}
            setIsOpen={setBussinessDetailsShow}
            userInfo={userInfo}
            closeModal={() => setBussinessDetailsShow(false)}
          />
          <AddMember
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            closeModal={() => setIsOpen(false)}
          />
          <ConnectUser
            modalIsOpen={modalIsOpenUser}
            setIsOpen={setIsOpenUser}
            userId={userId}
            showName={showName}
            closeModal={() => setIsOpenUser(false)}
          />
          <AdminChangePassword
            modalIsOpen={changePasswordMember}
            setIsOpen={setChangePasswordMember}
            userId={userId}
            closeModal={() => setChangePasswordMember(false)}
          />
          <DeleteUserModal
            modalIsOpen={deleteUserShow}
            setIsOpen={setDeleteUserShow}
            userId={userId}
            userType={4}
            closeModal={() => setDeleteUserShow(false)}
          />
        </div>
      ) : (
        <Profile
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setPageType={setPageType}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          getDetails={getDetails}
          history={history}
        />
      )}
    </>
  );
};

export default Members;
