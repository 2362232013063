import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "../forms/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessOptions,
  getOrganizationOptions,
} from "../store/actions/authAction";
import {
  addNewAd,
  getAds,
  getUsers,
  updateAdsPhoto,
} from "../store/actions/dashboardAction";
import { dataURLtoFile, getLocalData } from "../utils/functions";
import ImageCropModal from "./image-crop-modal";
import { CHURCH_UNITE_URL } from "../utils/config";

const userSchema = yup.object({
  title: yup.string().trim().required("Title is Required"),
  link: yup.string().trim().required("Link is Required"),
  image: yup.string().trim().required("Image is Required"),
  imageWeb: yup.string().trim().required("Image is Required"),
  businessId: yup.string().trim().required("Business is Required"),
  orgId: yup.string().trim().required("Organization is Required"),
});

const AddAdsFrom = ({ closeModal, modalIsOpenImage, setIsOpenImage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isLoadingImgWeb, setIsLoadingImgWeb] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgUrlWeb, setImgUrlWeb] = useState(null);
  const [imgType, setImageType] = useState("");
  const [avatar, setAvatar] = useState("");
  const { error } = useSelector((state) => state.ui);
  const { businessesOptions, organizationOptions } = useSelector(
    (state) => state.auth,
  );
  const [orgId, setOrgId] = useState("");
  const { userInfo } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();

  const userType = getLocalData("userType");

  useEffect(() => {
    dispatch(getBusinessOptions());
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
    if (userType == 3) {
      setValue("businessId", userInfo?.id);
      setValue("orgId", userInfo?.orgId);
      setOrgId(userInfo?.orgId);
    } else if (userType == 2) {
      setValue("orgId", userInfo?.id);
      setOrgId(userInfo?.id);
    }
  }, [userInfo]);

  const addHandler = async (data) => {
    const payload = {
      link: data?.link,
      title: data?.title,
      bannerImage: data?.image,
      bannerImageWeb: data?.imageWeb,
      businessId: +data?.businessId,
      orgId: +data?.orgId,
    };

    setIsLoading(true);
    const res = await dispatch(addNewAd(payload));
    if (res.status === "success") {
      await dispatch(getAds());
      closeModal();
    }
    setIsLoading(false);
  };

  const uploadImage = async (e, type) => {
    setImageType(type);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = (e) => {
        setAvatar(reader.result);
        setIsOpenImage(true);
      };
    }
  };

  const uploadImgage = async (url) => {
    setIsOpenImage(false);

    imgType === "web" ? setIsLoadingImgWeb(true) : setIsLoadingImg(true);

    var data = new FormData();
    const file = dataURLtoFile(url, "ads_photo.png");
    data.append("bannerImage", file);
    const res = await dispatch(updateAdsPhoto(data));

    if (res?.bannerImage) {
      if (imgType === "web") {
        setValue("imageWeb", res?.bannerImage);
        setImgUrlWeb(res?.bannerImage);
      } else {
        setValue("image", res?.bannerImage);
        setImgUrl(res?.bannerImage);
      }
    }
    imgType === "web" ? setIsLoadingImgWeb(false) : setIsLoadingImg(false);
  };

  return (
    <>
      {modalIsOpenImage ? (
        <div style={{ height: 500, width: 500 }}>
          <ImageCropModal
            avatar={avatar}
            uploadImgage={uploadImgage}
            imgType={imgType}
          />
        </div>
      ) : (
        <div className="authentication-wrapper add-member-modal">
          <div className="form-wizard">
            <form
              className="position-relative"
              onSubmit={handleSubmit(addHandler)}>
              <div className={`d-flex authentication-bg p-sm-5 p-4 login-ss`}>
                <div className="form-block mobile-margin-top">
                  {imgUrl ? (
                    <div>
                      <img
                        src={`https://clubunite.com/eleo-uploads/uploads/profilephotos/${getValues(
                          "image",
                        )}`}
                        className="add-image-show"
                      />
                      <i
                        onClick={() => {
                          setImgUrl(null);
                          setValue("image", null);
                        }}
                        className="far fa-pen me-2 add-ads-edit"></i>
                    </div>
                  ) : (
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        textAlign: "center",
                        backgroundColor: "#fff",
                      }}>
                      <label
                        disabled
                        for="uploadMobile"
                        className="file-upload mb-0 image-ads-upload"
                        tabindex="0"
                        style={{
                          backgroundColor: "#eee",
                        }}>
                        {isLoadingImg
                          ? "Uploading..."
                          : "Select Mobile Banner Image"}
                        <span
                          className="w-100 text-center mt-1"
                          style={{
                            fontSize: 14,
                            display: "block",
                            color:
                          window.location.href?.includes(CHURCH_UNITE_URL)
                                ? "#134169"
                                : "#006747",
                          }}>
                          Resolution: 800 X 100
                        </span>
                      </label>
                      <input
                        disabled={isLoadingImg}
                        type="file"
                        id="uploadMobile"
                        name="uploadMobile"
                        accept="image/png, image/jpeg, image/jpg"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "mobile")}
                      />
                    </div>
                  )}
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "2px",
                    }}>
                    {errors?.["image"]?.message}
                  </p>

                  {imgUrlWeb ? (
                    <div>
                      <img
                        src={`https://clubunite.com/eleo-uploads/uploads/profilephotos/${getValues(
                          "imageWeb",
                        )}`}
                        className="add-image-show-web"
                      />
                      <i
                        onClick={() => {
                          setImgUrlWeb(null);
                          setValue("imageWeb", null);
                        }}
                        className="far fa-pen me-2 add-ads-edit"></i>
                    </div>
                  ) : (
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        textAlign: "center",
                        backgroundColor: "#fff",
                      }}>
                      <label
                        disabled
                        for="uploadWeb"
                        className="file-upload mb-0 image-ads-upload-web"
                        tabindex="0"
                        style={{
                          backgroundColor: "#eee",
                        }}>
                        {isLoadingImgWeb
                          ? "Uploading..."
                          : "Select Web Banner Image"}
                        <span
                          className="w-100 text-center mt-1"
                          style={{
                            fontSize: 14,
                            display: "block",
                            color:
                          window.location.href?.includes(CHURCH_UNITE_URL)
                                ? "#134169"
                                : "#006747",
                          }}>
                          Resolution: 1280 X 100
                        </span>
                      </label>
                      <input
                        disabled={isLoadingImgWeb}
                        type="file"
                        id="uploadWeb"
                        name="uploadWeb"
                        accept="image/png, image/jpeg, image/jpg"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "web")}
                      />
                    </div>
                  )}
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "2px",
                    }}>
                    {errors?.["imageWeb"]?.message}
                  </p>

                  <TextField
                    errors={errors}
                    placeholder="Title"
                    register={register}
                    fieldName="title"
                  />

                  <TextField
                    errors={errors}
                    placeholder="Link"
                    register={register}
                    fieldName="link"
                  />
                  {userType == 1 && (
                    <div className="style-line">
                      <select
                        className="form-select form-control form-control-sm"
                        onChange={(e) => {
                          setValue("orgId", +e.target.value);
                          setOrgId(+e.target.value);
                        }}>
                        <option
                          value=""
                          disabled
                          selected>
                          Organization
                        </option>
                        {organizationOptions?.map((val) => (
                          <option
                            value={val?.id}
                            key={val?.id}>
                            {val?.name}
                          </option>
                        ))}
                      </select>
                      <span className="line"></span>
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}>
                        {errors?.["orgId"]?.message}
                      </p>
                    </div>
                  )}
                  {userType != 3 && (
                    <div className="style-line">
                      <select
                        className="form-select form-control form-control-sm"
                        onChange={(e) =>
                          setValue("businessId", +e.target.value)
                        }>
                        <option
                          value=""
                          disabled
                          selected>
                          Business
                        </option>
                        {businessesOptions
                          ?.filter((val) => val?.orgId === orgId)
                          ?.map((val) => (
                            <option
                              value={val?.id}
                              key={val?.id}>
                              {val?.name}
                            </option>
                          ))}
                      </select>
                      <span className="line"></span>
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}>
                        {errors?.["businessId"]?.message}
                      </p>
                    </div>
                  )}

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn w-100 mt-3 wizard-next-btn">
                    {isLoading ? "Saving" : "Save"}
                  </button>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                      marginTop: 5,
                    }}>
                    {error && !isLoading ? error : ""}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAdsFrom;
