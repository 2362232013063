import { getLocalData } from "../../utils/functions";
import { SET_DASHBOARD_DATA } from "../constants/dashboardConstants";

const initialState = {
  members: null,
  businesses: null,
  organization: null,
  userInfo: null,
  ads: null,
  connectedBussiness: null,
  displayAds: null,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
