import React, { useState } from "react";
import Modal from "react-modal";
import ImageCropModal from "./image-crop-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    zIndex: 100000,
    // backgroundColor: "#1E2A42",
  },
};

const CropModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  avatar,
  uploadImgage,
  imgType,
  org,
}) => {
  const [modalIsOpenImage, setIsOpenImage] = useState(false);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: -40,
            marginLeft: 20,
            marginRight: 20,
          }}>
          <p
            style={{
              fontSize: 18,
              fontWeight: 600,
              marginBottom: -40,
            }}></p>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: "#282828",
              zIndex: 10000,
            }}
            role="button"
            onClick={() =>
              modalIsOpenImage ? setIsOpenImage(false) : closeModal()
            }>
            X
          </a>
        </div>
        <div style={{ height: 500, width: 500 }}>
          <ImageCropModal
            avatar={avatar}
            uploadImgage={uploadImgage}
            imgType={imgType}
            org={org}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CropModal;
