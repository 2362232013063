import React, { useEffect } from "react";
import { getLocalData } from "../utils/functions";
import { CHURCH_UNITE_URL, CHURCH_URL_PREFIX, CLUB_URL_PREFIX } from "../utils/config";

const PrivateLayout = ({ children, history }) => {
  const token = getLocalData("token");

  useEffect(() => {
    if (!token && !window.location.search?.includes("?token=")) {
      window?.location?.href?.includes(CHURCH_UNITE_URL)
        ? history.push(`${CHURCH_URL_PREFIX}/login`)
        : history.push(`${CLUB_URL_PREFIX}/login`)
    }
    // eslint-disable-next-line
  }, [token, window.location]);

  return <>{children}</>;
};

export default PrivateLayout;
