import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider, useSelector } from "react-redux";
import store from "./store/store";
import "./assets/css/styles.css";

const GolfTheme = React.lazy(() => import("./themes/GolfTheme"));
const ChurchTheme = React.lazy(() => import("./themes/ChurchTheme"));

const root = ReactDOM.createRoot(document.getElementById("root"));

//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }) => {
  const { userInfo } = useSelector((state) => state.dashboard);
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(window?.location?.href?.includes("churchunite")) && (
          <ChurchTheme />
        )}
        {!window?.location?.href?.includes("churchunite") && <GolfTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

root.render(
  <Provider store={store}>
    <ThemeSelector>
      <App />
    </ThemeSelector>
  </Provider>,
);
